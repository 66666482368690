import React from "react";
import Header from "../../Components/Home/NavBar/Header";
import NavBar from "../../Components/Home/NavBar/NavBar";
import Footer from "../../Components/Home/Footer/Footer";
import './PrivacyPolicy.module.css'
const PrivacyPolicy = () => {

    return (
        <>
<Header/>
<NavBar/>
<section
      style={{
        // maxWidth: '1000px',
        margin: '20px auto',
        padding: '30px',
        backgroundColor: '#fff',
        // border: '1px solid #ddd',
        borderRadius: '8px',
        // boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        fontFamily: 'Arial, sans-serif',
        lineHeight: '1.6',
        color: '#333',
      }}
    >
      <h1 style={{ textAlign: 'center', marginBottom: '20px', color: '#4a4a4a' }}>
        Privacy Policy
      </h1>
      <p style={{ textAlign: 'center', color: '#777' }}>Last Updated: 03/09/2024</p>

      <p>
        Welcome to <strong>Baba Ki Booty</strong>. Your privacy is important to us, and we are
        committed to protecting your personal information. This Privacy Policy explains how we
        collect, use, disclose, and safeguard your information when you visit our website{' '}
        <a href="https://www.babakibooty.com/" style={{ color: '#1a73e8', textDecoration: 'none' }}>
          https://www.babakibooty.com/
        </a>{' '}
        or make a purchase from us.
      </p>

      <h3>1. Information We Collect</h3>
      <p>
        We may collect personal information that you voluntarily provide to us when you register on
        our site, place an order, subscribe to our newsletter, or otherwise interact with our
        website. This may include:
      </p>
      <ul style={{ marginLeft: '20px' }}>
        <li>
          <strong>Personal Identification Information:</strong> Name, email address, phone number,
          and billing address.
        </li>
        <li>
          <strong>Payment Information:</strong> Credit/debit card numbers and other financial data
          used to process your transactions.
        </li>
        <li>
          <strong>Order Information:</strong> Product details, order history, and other information
          related to your purchases.
        </li>
        <li>
          <strong>Technical Information:</strong> IP address, browser type, device information, and
          cookies.
        </li>
      </ul>

      <h3>2. How We Use Your Information</h3>
      <p>We use the information we collect for the following purposes:</p>
      <ul style={{ marginLeft: '20px' }}>
        <li>To process your orders and manage payments.</li>
        <li>To improve our website, products, and services.</li>
        <li>To communicate with you about your orders, offers, promotions, or updates.</li>
        <li>To personalize your shopping experience and provide better customer service.</li>
        <li>To prevent fraudulent transactions and ensure security.</li>
      </ul>

      <h3>3. Sharing Your Information</h3>
      <p>
        We do not sell, trade, or rent your personal information to third parties. However, we may
        share your information with:
      </p>
      <ul style={{ marginLeft: '20px' }}>
        <li>
          <strong>Service Providers:</strong> To help us manage our business operations, such as
          payment processing, shipping, and email communication.
        </li>
        <li>
          <strong>Legal Authorities:</strong> If required by law, to comply with legal obligations,
          or to protect the rights, property, or safety of our company, customers, or others.
        </li>
      </ul>

      <h3>4. Security of Your Information</h3>
      <p>
        We implement a variety of security measures to protect your personal information. However,
        please note that no method of transmission over the Internet or electronic storage is 100%
        secure. We strive to use commercially acceptable means to protect your personal information
        but cannot guarantee its absolute security.
      </p>

      <h3>5. Your Rights</h3>
      <p>You have the right to:</p>
      <ul style={{ marginLeft: '20px' }}>
        <li>Access, update, or delete your personal information.</li>
        <li>Opt out of receiving marketing communications from us.</li>
        <li>Request a copy of the data we hold about you.</li>
      </ul>
      <p>To exercise these rights, please contact us using the information provided below.</p>

      <h3>6. Cookies</h3>
      <p>
        Our website uses cookies to enhance your browsing experience. You can choose to accept or
        decline cookies. Declining cookies may prevent you from taking full advantage of the
        website.
      </p>

      <h3>7. Third-Party Links</h3>
      <p>
        Our website may contain links to other websites. We are not responsible for the privacy
        practices of those sites, and we encourage you to read their privacy policies.
      </p>

      <h3>8. Changes to This Privacy Policy</h3>
      <p>
        We may update this Privacy Policy from time to time. Any changes will be posted on this page
        with an updated date. We encourage you to review this policy periodically.
      </p>

      <h3>9. Contact Us</h3>
      <p>If you have any questions or concerns about our Privacy Policy, please contact us at:</p>
      <ul style={{ marginLeft: '20px' }}>
        <li>
          <strong>Email:</strong> babakibooty0@gmail.com
        </li>
        <li>
          <strong>Phone:</strong> (+91) 9266804260
        </li>
        <li>
          <strong>Address:</strong> house no-857, uttam vihar, Rohtak, PO:Rohtak, DIST:Rohtak, Haryana, 124001
        </li>
      </ul>

      <p>
        Thank you for trusting <strong>Baba Ki Booty</strong> with your personal information.
      </p>
    </section>
<Footer/>
</>
    )
}

export default PrivacyPolicy;