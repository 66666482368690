// import React, {useEffect,useState} from 'react';
// import {useDispatch, useSelector} from 'react-redux'

// const Hero = () => {
//   const dispatch = useDispatch()
//   const {products} = useSelector((state)=>state.products);

//   return (
//     <section>
//       <div className="w-full px-3 antialiased lg:px-6">
//         <div className="mx-auto max-w-7xl">
//           <div className="container py-24 mx-auto text-center sm:px-4 font-sans">
//             <h1 className="text-4xl font-semibold font-display leading-10 tracking-tight text-gray-800 sm:text-5xl sm:leading-none">
//               <span className="relative inline-block text-gray-800 tracking-normal">
//                 Your medication, delivered
//               </span>
//             </h1>
//             <div className="max-w-lg mx-auto mt-6 sm:mt-8 text-sm text-center text-gray-600 tracking-wide sm:text-base md:max-w-xl md:text-lg xl:text-xl">
//               Say goodbye to all your healthcare worries with us
//             </div>

//             <div className="relative flex items-center max-w-md mx-auto mt-12 overflow-hidden text-center rounded-full shadow-3xl">
//               {/* Search functionality for a medicine */}
//               <input
//                 type="text"
//                 name="search"
//                 placeholder="Search your medicine from here"
//                 className="w-full h-12 px-6 py-2 font-medium text-teal-800 placeholder-gray-500 tracking-wide focus:outline-none"
//               />
//               <span className="relative top-0 right-0 block ">
//                 <button type="button" className="hero-search-button">
//                   Search
//                 </button>
//               </span>
//             </div>

//             <div className="mt-12 text-base tracking-wider text-teal-300">
//               Take care of your healthcare now!
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default Hero;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {fetchProducts} from '../../../Redux/product/action'
const Hero = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { allProducts } = useSelector((state) => state.products);

  // State to manage search input and suggestions
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  // Handle search input change
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    // Filter products based on search term
    if (value) {
      const filteredSuggestions = allProducts.filter((product) =>
        product.name.toLowerCase().includes(value.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  };

  // Handle product suggestion click
  const handleProductClick = (productId) => {
    navigate(`/productDetails?productId=${productId}`);
  };

  useEffect(()=> {
    dispatch(fetchProducts('All'));
  },[dispatch])
  return (
    <section>
      <div className="w-full px-3 antialiased lg:px-6">
        <div className="mx-auto max-w-7xl">
          <div className="container py-24 mx-auto text-center sm:px-4 font-sans">
            <h1 className="text-4xl font-semibold font-display leading-10 tracking-tight text-gray-800 sm:text-5xl sm:leading-none">
              <span className="relative inline-block text-gray-800 tracking-normal">
                Your medication, delivered
              </span>
            </h1>
            <div className="max-w-lg mx-auto mt-6 sm:mt-8 text-sm text-center text-gray-600 tracking-wide sm:text-base md:max-w-xl md:text-lg xl:text-xl">
              Say goodbye to all your healthcare worries with us
            </div>

            <div className="relative flex items-center max-w-md mx-auto mt-12 overflow-hidden text-center rounded-full shadow-3xl">
              {/* Search functionality for a medicine */}
              <input
                type="text"
                name="search"
                placeholder="Search your medicine from here"
                className="w-full h-12 px-6 py-2 font-medium text-teal-800 placeholder-gray-500 tracking-wide focus:outline-none"
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <span className="relative top-0 right-0 block ">
                <button type="button" className="hero-search-button">
                  Search
                </button>
              </span>
            </div>

            {/* Suggestions List */}
            {suggestions.length > 0 && (
              <ul className="max-w-md mx-auto mt-2 bg-white border border-gray-200 rounded-lg shadow-lg">
                {suggestions.map((product) => (
                  <li
                    key={product._id}
                    className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                    onClick={() => handleProductClick(product._id)}
                  >
                    {product.name}
                  </li>
                ))}
              </ul>
            )}

            <div className="mt-12 text-base tracking-wider text-teal-300">
              Take care of your healthcare now!
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;

