import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts } from '../../Redux/product/action';
import {getAllCategoryAction} from '../../Redux/category/actions'
const Categories = ({search, setSearch}) => {
  const [selectedCategory, setSelectedCategory] = useState("All Products");
  const dispatch = useDispatch();
  const categoriesState = useSelector((state) => state.categories);
  const { loading, categories, error } = categoriesState;
  useEffect(() => {
    dispatch(getAllCategoryAction());
    dispatch(fetchProducts(selectedCategory));
  }, [selectedCategory, dispatch]);

  return (
    <div className="flex justify-between align-center">
      <h2 className="text-2xl font-display font-semibold tracking-wide text-teal-700">
        {selectedCategory}
      </h2>

      <div className="col-12 align-middle justify-content-center flex">
        <select
          className="mr-6 p-2 border border-teal-300 rounded text-teal-600 text-lg"
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
        >
          {categories.map((category) => (
            <option key={category.name} value={category.name}>
              {category.name}
            </option>
          ))}
          <option key="All Products" value="All Products">All</option>
        </select>
        <input
          type="text"
          className="col-6 border border-1 rounded p-1 sm:p-2 text-teal-800 border-teal-300 placeholder-gray-600 focus:border-teal-500"
          placeholder="Search products..."
          id="search-filter"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
    </div>
  );
};

export default Categories;

