import React from 'react';
import Header from '../../Components/Home/NavBar/Header';
import NavBar from '../../Components/Home/NavBar/NavBar';
import Footer from '../../Components/Home/Footer/Footer';
const ShippingAndDeliveryPolicy = () => {
  return (
    <>
    <Header/>
    <NavBar />
    <section
      style={{
        // maxWidth: '800px',
        margin: '20px auto',
        padding: '20px',
        backgroundColor: '#fff',
        // border: '1px solid #ddd',
        // borderRadius: '8px',
        // boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        fontFamily: 'Arial, sans-serif',
        lineHeight: '1.6',
        color: '#333',
      }}
    >
      <h1 style={{ textAlign: 'center', marginBottom: '20px', color: '#4a4a4a' }}>
        Shipping and Delivery Policy
      </h1>
      <p style={{ textAlign: 'center', color: '#777' }}>Last Updated: 03/09/2024</p>

      <p>
        At <strong>Baba Ki Booty</strong>, we are committed to delivering your orders in a timely and
        efficient manner. This Shipping and Delivery Policy outlines the terms and conditions
        related to the shipping of products purchased from our website{' '}
        <a href="https://www.babakibooty.com/" style={{ color: '#1a73e8', textDecoration: 'none' }}>
          https://www.babakibooty.com/
        </a>
        .
      </p>

      <h3>1. Shipping Locations</h3>
      <p>
        We currently ship our products across India. We do not offer international shipping at this
        time. Please ensure your shipping address is accurate and complete to avoid any delivery
        delays.
      </p>

      <h3>2. Processing Time</h3>
      <p>
        Orders are processed within 1-2 business days (excluding weekends and holidays) after
        receiving your order confirmation email. You will receive a notification once your order has
        been shipped. Please note that processing times may vary during peak seasons or due to
        unforeseen circumstances.
      </p>

      <h3>3. Shipping Methods and Delivery Time</h3>
      <p>
        We offer the following shipping options:
      </p>
      <ul style={{ marginLeft: '20px' }}>
        <li>
          <strong>Standard Shipping:</strong> Estimated delivery within 5-7 business days after
          dispatch.
        </li>
        <li>
          <strong>Express Shipping:</strong> Estimated delivery within 2-4 business days after
          dispatch. Additional charges may apply.
        </li>
      </ul>
      <p>
        Delivery times are estimates and are not guaranteed. Actual delivery time may vary based on
        location, carrier availability, and other factors outside of our control.
      </p>

      <h3>4. Shipping Charges</h3>
      <p>
        Shipping charges are calculated based on the weight, dimensions, and destination of your
        order. The shipping cost will be displayed at checkout before you complete your purchase.
        Free shipping may be available for orders above a certain amount, as specified on our
        website.
      </p>

      <h3>5. Order Tracking</h3>
      <p>
        Once your order is shipped, you will receive an email with tracking information. You can use
        the tracking number provided to monitor the status of your delivery on the carrier's
        website. Please allow up to 24 hours for the tracking information to update.
      </p>

      <h3>6. Delayed or Lost Shipments</h3>
      <p>
        While we strive to ensure timely delivery, there may be delays due to unforeseen
        circumstances such as weather, strikes, or logistical issues. If your order is delayed or
        lost, please contact us, and we will work with the carrier to resolve the issue.
      </p>

      <h3>7. Delivery Issues</h3>
      <p>
        If there are any delivery issues, such as missing items or damaged packages, please contact
        us within 48 hours of receiving your order. We may require photographic evidence of the
        damage to process a replacement or refund.
      </p>

      <h3>8. Address Changes</h3>
      <p>
        If you need to change your shipping address after placing an order, please contact us
        immediately. Address changes are not guaranteed and are subject to our discretion and order
        status. Additional fees may apply for rerouting.
      </p>

      <h3>9. Contact Us</h3>
      <p>
        If you have any questions or concerns about our Shipping and Delivery Policy, please contact
        us at:
      </p>
      <ul style={{ marginLeft: '20px' }}>
        <li>
          <strong>Email:</strong> babakibooty0@gmail.com
        </li>
        <li>
          <strong>Phone:</strong> (+91) 9266804260
        </li>
        <li>
          <strong>Address:</strong> house no-857, uttam vihar, Rohtak, PO:Rohtak, DIST:Rohtak, Haryana, 124001
        </li>
      </ul>

      <p>Thank you for shopping with <strong>Baba Ki Booty</strong>!</p>
    </section>
    <Footer/>
    </>
  );
};

export default ShippingAndDeliveryPolicy;
