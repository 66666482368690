import axios from 'axios';

const API = axios.create({ baseURL: 'https://medical-ecom-2.onrender.com' })

API.interceptors.request.use(req => {
  const token = JSON.parse(localStorage.getItem('profile'))?.token;
  if (token) {
    req.headers.Authorization = `bearer ${token}`
  }
  return req
})

//auth
export const createUserApi = formData => API.post(`/auth/sineUp`, formData)
export const loginUserApi = formData => API.post(`/auth/login`, formData)

//category

export const getAllCategory = () => API.get(`/category`)
export const createCategory = formData => API.post(`/category`, formData)
export const deleteCategory = id => API.delete(`/category?id=${id}`)

//product

export const getAllProduct = () => API.get(`/product`)
export const createProduct = formData => API.post(`/product`, formData)
export const updateProduct = (id, formData) => API.put(`/product?productId=${id}`, formData)
export const deleteProduct = id => API.delete(`/product?productId=${id}`)
export const getAllProductByCategory = category => API.get(`/product/categoryProduct?category=${category}`)
export const getProductById = id => API.get(`/product/productById?productId=${id}`)

//reviews

export const getAllReview = (id) => API.get(`/review?productId=${id}`)
export const createReview = (formData) => API.post(`/review`, formData)
export const deleteReview = (id) => API.delete(`/review?id=${id}`)
export const getOverview = (id) => API.get(`/review/overview?productId=${id}`)

//Cart

export const addToCart = (productId, quantity) => API.post(`/cart`, { productId, quantity })
export const getCart = () => API.get(`/cart`)
export const updateCartQuantity = (productId, quantity) => API.put(`/cart`, { productId, quantity })
export const deleteFromCart = productId => API.delete(`/cart?productId=${productId}`)

//orders

export const getAllOrder = () => API.get(`/order`)
export const createOrder = (formData) => API.post(`/order`, formData)
export const updateOrderStatus = (orderId, status) => API.put(`/order?orderId=${orderId}`, { status })
export const updateItemStatus = (orderId, itemId, status) => API.put(`/order/item?orderId=${orderId}&itemId=${itemId}`, { status })
export const getOrderById = orderId => API.get(`/order/single-order?orderId=${orderId}`)
export const getOrderByUserId = userId => API.get(`/order/user-order`)

//Users

export const getAllUsers = () => API.get(`/user`)

// Message

export const sendMessage = (formData) => API.post(`/message`, formData)
export const getAllMessages = () => API.get(`/message`)

export default API;