import {createCategory, getAllCategory, deleteCategory } from "../../Api";
import { showNotification } from "../notification/actions";

export const CREATE_CATEGORY_REQUEST = "CREATE_CATEGORY_REQUEST";
export const CREATE_CATEGORY_SUCCESS = "CREATE_CATEGORY_SUCCESS";
export const CREATE_CATEGORY_FAILURE = "CREATE_CATEGORY_FAILURE";

export const GET_CATEGORY_REQUEST = "GET_CATEGORY_REQUEST";
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS";
export const GET_CATEGORY_FAILURE = "GET_CATEGORY_FAILURE";

export const DELETE_CATEGORY_REQUEST = "DELETE_CATEGORY_REQUEST";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_FAILURE = "DELETE_CATEGORY_FAILURE";


export const createCategoryAction = (formData) => async (dispatch) => {
    try {
        const { data } = await createCategory(formData);
        dispatch({ type: CREATE_CATEGORY_SUCCESS, payload: data });
        dispatch(showNotification({ massageType: "success", massage: "Category created successfully." }));
    } catch (error) {
        dispatch(showNotification({ massageType: "error", massage: "Failed to create category." }));
    }
};

export const getAllCategoryAction = () => async (dispatch) => {
    try {
        const { data } = await getAllCategory();
        console.log(data);
        dispatch({ type: GET_CATEGORY_SUCCESS, payload: data });
    } catch (error) {
        dispatch(showNotification({ massageType: "error", massage: "Failed to get categories." }));
    }
};

export const deleteCategoryAction = (id) => async (dispatch) => {
    try {
        await deleteCategory(id);
        dispatch({ type: DELETE_CATEGORY_SUCCESS, payload: id });
        dispatch(showNotification({ massageType: "success", massage: "Category deleted successfully." }));
    } catch (error) {
        dispatch(showNotification({ massageType: "error", massage: "Failed to delete category." }));
    }
};