// import React from 'react';
// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// import VenderDashboardPage from './Pages/Admin/Vendor/VenderDashboardPage';
// import LoginPage from './Pages/Authentication/LoginPage';
// import RegistrationPage from './Pages/Authentication/RegistrationPage';
// import CheckoutPage from './Pages/Checkout/CheckoutPage';
// import Home from './Pages/Home/Home';
// import OrderHistory from './Pages/OrderHistory/OrderHistory';
// import ProductDetails from './Pages/ProductDetails/ProductDetails';
// import AllShop from './Pages/Shop/AllShop';
// import ShopDetailsPage from './Pages/Shop/ShopDetailsPage';

// const Navigation = () => {
//   return (
//     <div>
//       <Router>
//         <Switch>
//           <Route exact path="/">
//             <Home />
//           </Route>
//           <Route exact path="/login">
//             <LoginPage />
//           </Route>
//           <Route exact path="/registration">
//             <RegistrationPage />
//           </Route>
//           <Route path="/shopDetails/:vendorId">
//             <ShopDetailsPage />
//           </Route>
//           <Route path="/checkout">
//             <CheckoutPage />
//           </Route>
//           <Route exact path="/productDetails">
//             <ProductDetails />
//           </Route>
//           <Route exact path="/vendor/:panel">
//             <VenderDashboardPage />
//           </Route>
//           <Route path="/allShop">
//               <AllShop></AllShop>
//           </Route>
//           <Route path="/orderHistory">
//             <OrderHistory></OrderHistory>
//           </Route>
//         </Switch>
//       </Router>
//     </div>
//   );
// };

// export default Navigation;
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import VenderDashboardPage from './Pages/Admin/Vendor/VenderDashboardPage';
import LoginPage from './Pages/Authentication/LoginPage';
import RegistrationPage from './Pages/Authentication/RegistrationPage';
import CheckoutPage from './Pages/Checkout/CheckoutPage';
import Home from './Pages/Home/Home';
import OrderHistory from './Pages/OrderHistory/OrderHistory';
import ProductDetails from './Pages/ProductDetails/ProductDetails';
import AllShop from './Pages/Shop/AllShop';
import ShopDetailsPage from './Pages/Shop/ShopDetailsPage';
import AllProducts from './Pages/AllProducts/AllProducts'
import MyOrder from './Pages/myorders/MyOrders';
import PrivacyPolicy from './Pages/privacy/PrivacyPolicy'
import Terms from './Pages/terms/Terms'
import Cancellation from './Pages/cancellation/Cancellation'
import ShippingAndDelivery from './Pages/shippingAndDlivery/shippingAndDelivery'
const Navigation = () => {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/registration" element={<RegistrationPage />} />
          <Route path="/shopDetails/:vendorId" element={<ShopDetailsPage />} />
          <Route path="/checkout" element={<CheckoutPage />} />
          <Route path="/productDetails" element={<ProductDetails />} />
          <Route path="/vendor/:panel" element={<VenderDashboardPage />} />
          <Route path="/allShop" element={<AllShop />} />
          <Route path="/orderHistory" element={<OrderHistory />} />
          <Route path="/products" element={<AllProducts />} />
          <Route path="/my-orders" element={<MyOrder />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/cancellation" element={<Cancellation />} />
          <Route path="/shipping-delivery" element={<ShippingAndDelivery />} />

        </Routes>
      </Router>
    </div>
  );
};

export default Navigation;
