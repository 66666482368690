// import React, { useState, useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import AddNewProducts from './AddNewProducts';
// import { Link } from 'react-router-dom';
// import { fetchProducts, createProductAction, updateProductAction, deleteProductAction } from '../../../../Redux/product/action';
// // import { showNotification } from '../../redux/actions/notificationActions';

// const AllProducts = () => {
//   const dispatch = useDispatch();
//   const { products, loading, error } = useSelector((state) => state.products);

//   const [editModal, setEditModal] = useState(false);
//   const [viewProduct, setViewProduct] = useState(null);

//   useEffect(() => {
//     dispatch(fetchProducts("All"));
//   }, [dispatch]);

//   const handleCreateProduct = (productData) => {
//     dispatch(createProductAction(productData))
//       .then(() => {
//         // dispatch(showNotification('Product created successfully!', 'success'));
//         setEditModal(false);
//       })
//       .catch((err) => {
//         // dispatch(showNotification('Failed to create product.', 'error'));
//       });
//   };

//   const handleUpdateProduct = (productId, productData) => {
//     dispatch(updateProductAction(productId, productData))
//       .then(() => {
//         // dispatch(showNotification('Product updated successfully!', 'success'));
//         setEditModal(false);
//       })
//       .catch((err) => {
//         // dispatch(showNotification('Failed to update product.', 'error'));
//       });
//   };

//   const handleDeleteProduct = (productId) => {
//     dispatch(deleteProductAction(productId))
//       .then(() => {
//         // dispatch(showNotification('Product deleted successfully!', 'success'));
//       })
//       .catch((err) => {
//         // dispatch(showNotification('Failed to delete product.', 'error'));
//       });
//   };

//   return (
//     <section>
//       <div className="container mx-auto px-4 sm:px-8 max-w-full sm:max-w-5xl">
//         <div className="flex flex-row mb-1 sm:mb-0 justify-between w-full">
//           <div className="flex-start">
//             <button
//               className="flex-shrink-0 px-4 py-2 text-base font-medium tracking-wide text-white bg-teal-500 rounded-lg shadow-md hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2 focus:ring-offset-teal-200"
//               onClick={() => {
//                 setViewProduct(null);
//                 setEditModal(true);
//               }}
//             >
//               Add a new Product
//             </button>
//             <Link to="/vendor/categories">
//               <button
//                 className="flex-shrink-0 px-4 py-2 text-base font-medium tracking-wide text-white bg-teal-500 rounded-lg shadow-md hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2 focus:ring-offset-teal-200 ml-6"
//                 type="submit"
//               >
//                 Add Category
//               </button>
//             </Link>
//           </div>
//           <div className="text-end">
//             <form className="flex flex-col md:flex-row w-3/4 md:w-full max-w-sm md:space-x-3 space-y-3 md:space-y-0 justify-center">
//               <div className="relative">
//                 <input
//                   type="text"
//                   id="form-subscribe-filter"
//                   className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-teal-600 focus:border-transparent"
//                   placeholder="Product Name"
//                 />
//               </div>
//               <button
//                 className="flex-shrink-0 px-4 py-2 text-base font-medium tracking-wide text-white bg-teal-500 rounded-lg shadow-md hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2 focus:ring-offset-teal-200"
//                 type="submit"
//               >
//                 Filter
//               </button>
//             </form>
//           </div>
//         </div>

//         {/* View all products */}
//         <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
//           <div className="inline-block min-w-full shadow-3xl rounded-lg overflow-hidden">
//             <table className="min-w-full leading-normal font-sans tracking-wide">
//               <thead>
//                 <tr>
//                   <th
//                     scope="col"
//                     className="px-5 pb-3 pt-4 bg-teal-100 border-b border-gray-200 text-gray-800 text-left text-sm uppercase font-semibold"
//                   >
//                     Product Info
//                   </th>
//                   <th
//                     scope="col"
//                     className="px-5 pb-3 pt-4 bg-teal-100 border-b border-gray-200 text-gray-800 text-left text-sm uppercase font-semibold"
//                   >
//                     Category
//                   </th>
//                   <th
//                     scope="col"
//                     className="px-5 pb-3 pt-4 bg-teal-100 border-b border-gray-200 text-gray-800 text-left text-sm uppercase font-semibold"
//                   >
//                     Price
//                   </th>
//                   <th
//                     scope="col"
//                     className="px-5 pb-3 pt-4 bg-teal-100 border-b border-gray-200 text-gray-800 text-center text-sm uppercase font-semibold"
//                   >
//                     Action
//                   </th>
//                 </tr>
//               </thead>

//               {/* All products data row */}
//               {products.map((product) => (
//                 <tbody key={product._id}>
//                   <tr>
//                     <td className="px-5 py-5 border-b border-gray-200 bg-white font-body font-medium text-sm">
//                       <div className="flex items-center w-24 sm:w-full">
//                         <div className="flex-shrink-0">
//                           <a href="/" className="block relative">
//                             <img
//                               alt="Product"
//                               src={product.image}
//                               className="mx-auto object-cover rounded-full h-10 w-10"
//                             />
//                           </a>
//                         </div>
//                         <div className="ml-3">
//                           <p className="text-gray-900 whitespace-no-wrap">
//                             {product.name}
//                           </p>
//                         </div>
//                       </div>
//                     </td>
//                     <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
//                       <p className="text-gray-900 whitespace-no-wrap">
//                         {product.category}
//                       </p>
//                     </td>
//                     <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
//                       <p className="text-gray-900 whitespace-no-wrap">
//                         ৳ {product.price}
//                       </p>
//                     </td>
//                     <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
//                       <div className="flex align-center justify-center">
//                         <button
//                           className="flex align-center justify-center font-body font-semibold text-yellow-900 hover:text-orange-900"
//                           onClick={() => {
//                             setViewProduct(product);
//                             setEditModal(true);
//                           }}
//                         >
//                           <svg
//                             xmlns="http://www.w3.org/2000/svg"
//                             className="h-6 w-6"
//                             fill="none"
//                             viewBox="0 0 24 24"
//                             stroke="currentColor"
//                           >
//                             <path
//                               strokeLinecap="round"
//                               strokeLinejoin="round"
//                               strokeWidth={2}
//                               d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
//                             />
//                           </svg>{' '}
//                           Edit
//                         </button>
//                         <button
//                           className="flex align-center ml-5 justify-center font-body font-semibold text-red-600 hover:text-red-900"
//                           onClick={() => handleDeleteProduct(product._id)}
//                         >
//                           <svg
//                             xmlns="http://www.w3.org/2000/svg"
//                             className="h-6 w-6"
//                             fill="none"
//                             viewBox="0 0 24 24"
//                             stroke="currentColor"
//                           >
//                             <path
//                               strokeLinecap="round"
//                               strokeLinejoin="round"
//                               strokeWidth={2}
//                               d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
//                             />
//                           </svg>{' '}
//                           Delete
//                         </button>
//                       </div>
//                     </td>
//                   </tr>
//                 </tbody>
//               ))}
//             </table>

//             {/* Pagination */}
//             <div className="px-5 bg-white py-5 flex flex-col xs:flex-row items-center xs:justify-between">
//               <span className="text-xs xs:text-sm text-gray-900">
//                 Showing 1 to 10 of 50 Entries
//               </span>
//               <div className="inline-flex mt-2 xs:mt-0">
//                 <button className="text-sm text-teal-700 hover:text-white hover:bg-teal-800 bg-gray-100 font-semibold py-2 px-4 rounded-l">
//                   Prev
//                 </button>
//                 <button className="text-sm text-teal-700 hover:text-white hover:bg-teal-800 bg-gray-100 font-semibold py-2 px-4 rounded-r">
//                   Next
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>

//         {/* Edit Product Modal */}
//         {editModal && (
//           <div className="modal">
//             <AddNewProducts
//               product={viewProduct}
//               onCreate={handleCreateProduct}
//               onUpdate={handleUpdateProduct}
//               onClose={() => setEditModal(false)}
//             />
//           </div>
//         )}
//       </div>
//     </section>
//   );
// };

// export default AllProducts;
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EditProductModal from './EditProductModal';
import { fetchProducts, createProductAction, updateProductAction, deleteProductAction } from '../../../../Redux/product/action';
import { Link } from 'react-router-dom';
const AllProducts = () => {
  const dispatch = useDispatch();
  const { products, loading, error } = useSelector((state) => state.products);

  const [editModal, setEditModal] = useState(false);
  const [viewProduct, setViewProduct] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 10;

  useEffect(() => {
    dispatch(fetchProducts('All Products'));
  }, [dispatch]);

  const handleCreateProduct = (productData) => {
    dispatch(createProductAction(productData))
      .then(() => setEditModal(false))
      .catch((err) => console.error('Failed to create product:', err));
  };

  const handleUpdateProduct = (productId, productData) => {
    dispatch(updateProductAction(productId, productData))
      .then(() => setEditModal(false))
      .catch((err) => console.error('Failed to update product:', err));
  };

  const handleDeleteProduct = (productId) => {
    dispatch(deleteProductAction(productId))
      .catch((err) => console.error('Failed to delete product:', err));
  };

  const filteredProducts = products.filter(product =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.elements.search.value);
  };

  return (
    <section>
      <div className="container mx-auto px-4 sm:px-8 max-w-full sm:max-w-5xl">
        <div className="flex flex-row mb-1 sm:mb-0 justify-between w-full">
          <div className="flex-start">
            <button
              className="flex-shrink-0 px-4 py-2 text-base font-medium tracking-wide text-white bg-teal-500 rounded-lg shadow-md hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2 focus:ring-offset-teal-200"
              onClick={() => {
                setViewProduct(null);
                setEditModal(true);
              }}
            >
              Add a new Product
            </button>
            
          </div>
          <form onSubmit={handleSearch} className="flex flex-col md:flex-row w-2/4 md:w-full max-w-sm md:space-x-3 space-y-3 md:space-y-0 justify-center">
            <div className="relative">
              <input
                type="text"
                id="form-subscribe-filter"
                name="search"
                className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-teal-600 focus:border-transparent"
                placeholder="Product Name"
              />
            </div>
            <button
              className="flex-shrink-0 px-4 py-2 text-base font-medium tracking-wide text-white bg-teal-500 rounded-lg shadow-md hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2 focus:ring-offset-teal-200"
              type="submit"
            >
              Filter
            </button>
          </form>
        </div>

        {/* View all products */}
        <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
          <div className="inline-block min-w-full shadow-3xl rounded-lg overflow-hidden">
            <table className="min-w-full leading-normal font-sans tracking-wide">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="px-5 pb-3 pt-4 bg-teal-100 border-b border-gray-200 text-gray-800 text-left text-sm uppercase font-semibold"
                  >
                    Product Info
                  </th>
                  <th
                    scope="col"
                    className="px-5 pb-3 pt-4 bg-teal-100 border-b border-gray-200 text-gray-800 text-left text-sm uppercase font-semibold"
                  >
                    Category
                  </th>
                  <th
                    scope="col"
                    className="px-5 pb-3 pt-4 bg-teal-100 border-b border-gray-200 text-gray-800 text-left text-sm uppercase font-semibold"
                  >
                    Price
                  </th>
                  <th
                    scope="col"
                    className="px-5 pb-3 pt-4 bg-teal-100 border-b border-gray-200 text-gray-800 text-center text-sm uppercase font-semibold"
                  >
                    Action
                  </th>
                </tr>
              </thead>

              {/* All products data row */}
              <tbody>
                {
                currentProducts.map((product) => (
                  <tr key={product._id}>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white font-body font-medium text-sm">
                      <Link to={`/productDetails?productId=${product._id}`}>
                      <div className="flex items-center w-24 sm:w-full">
                        <div className="flex-shrink-0">
                          <a href="/" className="block relative">
                            <img
                              alt="Product"
                              src={product.image}
                              className="mx-auto object-cover rounded-full h-10 w-10"
                            />
                          </a>
                        </div>
                        <div className="ml-3">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {product.name}
                          </p>
                        </div>
                      </div>
                      </Link>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">
                        {product.category}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">
                      &#8377; {product.price.toFixed(2)}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-center">
                      <button
                        className="text-indigo-600 hover:text-indigo-900"
                        onClick={() => {
                          setViewProduct(product);
                          setEditModal(true);
                        }}
                      >
                        Edit
                      </button>
                      {/* <button
                        className="text-red-600 hover:text-red-900 ml-4"
                        onClick={() => handleDeleteProduct(product._id)}
                      >
                        Delete
                      </button> */}
                    </td>
                  </tr>
                ))
              }
              </tbody>
            </table>
          </div>

          {/* Pagination */}
          <div className="mt-4 flex justify-center">
            <nav>
              <ul className="flex list-style-none">
                {[...Array(Math.ceil(filteredProducts.length / productsPerPage)).keys()].map((page) => (
                  <li key={page} className="mr-1">
                    <button
                      onClick={() => handlePageChange(page + 1)}
                      className={`px-3 py-1 border rounded ${currentPage === page + 1 ? 'bg-teal-500 text-white' : 'bg-white text-teal-500'}`}
                    >
                      {page + 1}
                    </button>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </div>

      {/* Edit Product Modal */}
      {editModal && (
        <EditProductModal
          product={viewProduct}
          onClose={() => setEditModal(false)}
          onCreate={handleCreateProduct}
          onUpdate={handleUpdateProduct}
        />
      )}
    </section>
  );
};

export default AllProducts;
