// import React, { useState, useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';

// import {
//     getAllCategoryAction,
//   } from '../../../../Redux/category/actions';
// const EditProductModal = ({ product, onClose, onCreate, onUpdate }) => {
//   const dispatch = useDispatch();
//   const { categories, loading } = useSelector((state) => state.categories);

//     useEffect(() => {
//         dispatch(getAllCategoryAction());
//       }, [dispatch]);

//   const [formData, setFormData] = useState({
//     name: '',
//     category: '',
//     price: '',
//     image: ''
//   });

//   useEffect(() => {
//     if (product) {
//       setFormData({
//         name: product.name || '',
//         category: product.category || '',
//         price: product.price || '',
//         image: product.image || '',
//         description: product.description || '',
//       });
//     }
//   }, [product]);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prev) => ({ ...prev, [name]: value }));
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     if (product) {
//       onUpdate(product._id, formData);
//     } else {
//       onCreate(formData);
//     }
//   };

//   return (
//     <section>
//       <div className="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none backdrop-filter saturate-150 backdrop-blur-sm">
//         <div className="container items-center px-5 py-12 lg:px-20 ml-0 lg:ml-20">
//           <div
//             className="w-full mx-auto border rounded-lg shadow-xl text-gray-800 bg-white"
//             aria-hidden="false"
//             aria-describedby="modalDescription"
//             role="dialog"
//           >
//             <div className="bg-white shadow overflow-hidden sm:rounded-lg font-body font-medium">
//               <div className="flex items-center justify-between pt-8 px-5 mb-4">
//                 <div>
//                   <h3 className="text-lg leading-6 font-medium text-gray-900">
//                     Profile
//                   </h3>
//                   <p className="mt-1 max-w-2xl text-sm text-gray-500">
//                     This information will be displayed publicly so be careful
//                     what you share.
//                   </p>
//                 </div>

//                 <button
//                   className="justify-end p-1 mr-4 -mt-6 transition-colors duration-200 transform rounded-md hover:bg-opacity-25 text-teal-600 hover:bg-teal-700 focus:outline-none"
//                   type="button"
//                   aria-label="Close"
//                   aria-hidden="true"
//                   onClick={onClose}
//                 >
//                   <svg
//                     xmlns="http://www.w3.org/2000/svg"
//                     className="icon icon-tabler icon-tabler-circle-x"
//                     width="24"
//                     height="24"
//                     viewBox="0 0 24 24"
//                     stroke-width="1.5"
//                     stroke="currentColor"
//                     fill="none"
//                     stroke-linecap="round"
//                     stroke-linejoin="round"
//                   >
//                     <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
//                     <circle cx="12" cy="12" r="9"></circle>
//                     <path d="M10 10l4 4m0 -4l-4 4"></path>
//                   </svg>
//                 </button>
//               </div>
//             </div>
//             {/* Product Details */}
//             <form className="p-5 space-y-8 divide-y divide-gray-200">
//               <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
//                 <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
//                   <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-3">
//                     <label
//                       htmlFor="name"
//                       className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
//                     >
//                       Product Name
//                     </label>
//                     <div className="mt-1 sm:mt-0 sm:col-span-2">
//                       <div className="max-w-lg flex rounded-md shadow-sm">
//                         <input
//                           type="text"
//                           name="name"
//                           id="name"
//                           value={formData.name}
//                           onChange={handleChange}
//                           className="flex-1 block w-full focus:ring-teal-500 focus:border-teal-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
//                         />
//                       </div>
//                     </div>
//                   </div>

//                   <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-3">
//                     <label
//                       htmlFor="price"
//                       className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
//                     >
//                       Product Price
//                     </label>
//                     <div className="mt-1 sm:mt-0 sm:col-span-2">
//                       <div className="max-w-lg flex rounded-md shadow-sm">
//                         <input
//                           type="number"
//                           name="price"
//                           id="price"
//                           value={formData.price}
//                           onChange={handleChange}
//                           className="flex-1 block w-full focus:ring-teal-500 focus:border-teal-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
//                         />
//                       </div>
//                     </div>
//                   </div>

//                   <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
//                     <label
//                       htmlFor="description"
//                       className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
//                     >
//                       Product Details
//                     </label>
//                     <div className="mt-1 sm:mt-0 sm:col-span-2">
//                       <textarea
//                         id="description"
//                         name="description"
//                         rows={3}
//                         className="max-w-lg shadow-sm block w-full focus:ring-teal-500 focus:border-teal-500 sm:text-sm border border-gray-300 rounded-md"
//                          value={formData.description}
//                           onChange={handleChange}
//                       />
//                       <p className="mt-2 text-sm text-gray-500">
//                         Write a few sentences about product.
//                       </p>
//                     </div>
//                   </div>

//                   <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
//                     <label
//                       htmlFor="cover-photo"
//                       className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
//                     >
//                       Product Image
//                     </label>
//                     <div className="mt-1 sm:mt-0 sm:col-span-2">
//                       <div className="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
//                         <div className="space-y-1 text-center">
//                           <svg
//                             className="mx-auto h-12 w-12 text-gray-400"
//                             stroke="currentColor"
//                             fill="none"
//                             viewBox="0 0 48 48"
//                             aria-hidden="true"
//                           >
//                             <path
//                               d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
//                               strokeWidth={2}
//                               strokeLinecap="round"
//                               strokeLinejoin="round"
//                             />
//                           </svg>
//                           <div className="flex text-sm text-gray-600">
//                             <label
//                               htmlFor="file-upload"
//                               className="relative cursor-pointer bg-white rounded-md font-medium text-teal-600 hover:text-teal-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-teal-500"
//                             >
//                               <span>Upload a file</span>
//                               <input
//                                 id="file-upload"
//                                 name="file-upload"
//                                 type="file"
//                                 className="sr-only"
//                               />
//                             </label>
//                             <p className="pl-1">or drag and drop</p>
//                           </div>
//                           <p className="text-xs text-gray-500">
//                             PNG, JPG, GIF up to 10MB
//                           </p>
//                         </div>
//                       </div>
//                     </div>
//                   </div>

//                   <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
//                     <label
//                       htmlFor="country"
//                       className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
//                     >
//                       Category
//                     </label>
//                     <div className="mt-1 sm:mt-0 sm:col-span-2">
//                       <select
//                         id="category"
//                         name="category"
//                         autoComplete="category"
//                         className="max-w-lg block focus:ring-teal-500 focus:border-teal-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
//                         value={formData.category}
//                         onChange={handleChange}
//                       >
//                         {
//                             categories.map((category) => (
//                               <option key={category._id} value={category.name}>
//                                 {category.name}
//                               </option>
//                             ))
  
//                         }
                       
//                       </select>
//                     </div>
//                   </div>
//                 </div>

                
//               </div>

//               <div className="pt-5">
//                 <div className="flex justify-end">
//                   <button
//                     type="button"
//                     className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
//                   >
//                     Cancel
//                   </button>
//                   <button
//                     className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
//                     onClick={handleSubmit}
//                   >
//                     Save
//                   </button>
//                 </div>
//               </div>
//             </form>
//           </div>
//         </div>
//       </div>

//       {/* Background Modal Opacity */}
//       <div className="opacity-25 fixed inset-0 z-40 bg-gray-900" />
//     </section>
//   );
// };

// export default EditProductModal;


import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCategoryAction } from '../../../../Redux/category/actions';

const EditProductModal = ({ product, onClose, onCreate, onUpdate }) => {
  const dispatch = useDispatch();
  const { categories, loading } = useSelector((state) => state.categories);

  useEffect(() => {
    dispatch(getAllCategoryAction());
  }, [dispatch]);

  const [formData, setFormData] = useState({
    name: '',
    category: '',
    price: '',
    image: '',
    description: '',
  });

  const [imagePreview, setImagePreview] = useState(null);

  useEffect(() => {
    if (product) {
      setFormData({
        name: product.name || '',
        category: product.category || '',
        price: product.price || '',
        image: product.image || '',
        description: product.description || '',
      });
      setImagePreview(product.image || null);
    }
  }, [product]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'image' && files && files[0]) {
        console.log("files----->"+files,"files[0]--->"+files[0]);
      const file = files[0];
      setFormData((prev) => ({ ...prev, image: files }));
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Create a new FormData object
    const data = new FormData();

    // Append the form fields to the FormData object
    data.append('name', formData.name);
    data.append('category', formData.category);
    data.append('price', formData.price);
    data.append('description', formData.description);

    // Check if an image file is selected and append it to FormData
    if (formData.image && formData.image[0]) {
        data.append('image', formData.image[0]); // Append the first file from the FileList
    }

    // Call onCreate or onUpdate with the FormData object
    if (product) {
        onUpdate(product._id, data);
    } else {
        onCreate(data);
    }
};


  return (
    <section>
      <div className="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none backdrop-filter saturate-150 backdrop-blur-sm">
        <div className="container items-center px-5 py-12 lg:px-20 ml-0 lg:ml-20">
          <div
            className="w-full mx-auto border rounded-lg shadow-xl text-gray-800 bg-white"
            aria-hidden="false"
            aria-describedby="modalDescription"
            role="dialog"
          >
            <div className="bg-white shadow overflow-hidden sm:rounded-lg font-body font-medium">
              <div className="flex items-center justify-between pt-8 px-5 mb-4">
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Profile
                  </h3>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    This information will be displayed publicly so be careful
                    what you share.
                  </p>
                </div>

                <button
                  className="justify-end p-1 mr-4 -mt-6 transition-colors duration-200 transform rounded-md hover:bg-opacity-25 text-teal-600 hover:bg-teal-700 focus:outline-none"
                  type="button"
                  aria-label="Close"
                  aria-hidden="true"
                  onClick={onClose}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-circle-x"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <circle cx="12" cy="12" r="9"></circle>
                    <path d="M10 10l4 4m0 -4l-4 4"></path>
                  </svg>
                </button>
              </div>
            </div>
            {/* Product Details */}
            <form className="p-5 space-y-8 divide-y divide-gray-200">
              <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                  {/* Product Name */}
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-3">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Product Name
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <div className="max-w-lg flex rounded-md shadow-sm">
                        <input
                          type="text"
                          name="name"
                          id="name"
                          value={formData.name}
                          onChange={handleChange}
                          className="flex-1 block w-full focus:ring-teal-500 focus:border-teal-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                        />
                      </div>
                    </div>
                  </div>

                  {/* Product Price */}
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-3">
                    <label
                      htmlFor="price"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Product Price
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <div className="max-w-lg flex rounded-md shadow-sm">
                        <input
                          type="number"
                          name="price"
                          id="price"
                          value={formData.price}
                          onChange={handleChange}
                          className="flex-1 block w-full focus:ring-teal-500 focus:border-teal-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                        />
                      </div>
                    </div>
                  </div>

                  {/* Product Details */}
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="description"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Product Details
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <textarea
                        id="description"
                        name="description"
                        rows={3}
                        className="max-w-lg shadow-sm block w-full focus:ring-teal-500 focus:border-teal-500 sm:text-sm border border-gray-300 rounded-md"
                        value={formData.description}
                        onChange={handleChange}
                      />
                      <p className="mt-2 text-sm text-gray-500">
                        Write a few sentences about the product.
                      </p>
                    </div>
                  </div>

                  {/* Product Image */}
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="image"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Product Image
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <div className="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                        <div className="space-y-1 text-center">
                          {imagePreview ? (
                            <>
                            <img
                              src={imagePreview}
                              alt="Product"
                              className="mx-auto h-48 w-auto"
                            />
                               
                              <div className="flex text-sm text-gray-600">
                                <label
                                  htmlFor="image"
                                  className="relative cursor-pointer bg-white rounded-md font-medium text-teal-600 hover:text-teal-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-teal-500"
                                >
                                  <span>Replace a file</span>
                                  <input
                                    id="image"
                                    name="image"
                                    type="file"
                                    onChange={handleChange}
                                    className="sr-only"
                                  />
                                </label>
                                <p className="pl-1">or drag and drop</p>
                              </div>
                              <p className="text-xs text-gray-500">
                                PNG, JPG, GIF up to 10MB
                              </p>
</>
                            
                          ) : (
                            <>
                              <svg
                                className="mx-auto h-12 w-12 text-gray-400"
                                stroke="currentColor"
                                fill="none"
                                viewBox="0 0 48 48"
                                aria-hidden="true"
                              >
                                <path
                                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              <div className="flex text-sm text-gray-600">
                                <label
                                  htmlFor="image"
                                  className="relative cursor-pointer bg-white rounded-md font-medium text-teal-600 hover:text-teal-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-teal-500"
                                >
                                  <span>Upload a file</span>
                                  <input
                                    id="image"
                                    name="image"
                                    type="file"
                                    onChange={handleChange}
                                    className="sr-only"
                                  />
                                </label>
                                <p className="pl-1">or drag and drop</p>
                              </div>
                              <p className="text-xs text-gray-500">
                                PNG, JPG, GIF up to 10MB
                              </p>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Product Category */}
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="category"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Product Category
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <select
                        id="category"
                        name="category"
                        className="max-w-lg block w-full shadow-sm focus:ring-teal-500 focus:border-teal-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                        value={formData.category}
                        onChange={handleChange}
                      >
                        {!loading &&
                          categories.map((category) => (
                            <option key={category._id} value={category.name}>
                              {category.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pt-5">
                <div className="flex justify-end">
                  <button
                    type="button"
                    className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                    onClick={handleSubmit}
                  >
                    {product ? 'Update Product' : 'Create Product'}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EditProductModal;
