import { 
    CREATE_REVIEW_REQUEST,
    CREATE_REVIEW_SUCCESS,
    CREATE_REVIEW_FAILURE, 
    GET_REVIEW_REQUEST,
    GET_REVIEW_SUCCESS,
    GET_REVIEW_FAILURE,
    DELETE_REVIEW_REQUEST,
    DELETE_REVIEW_SUCCESS,
    DELETE_REVIEW_FAILURE, 
    GET_OVERVIEW_REQUEST,
    GET_OVERVIEW_SUCCESS,
    GET_OVERVIEW_FAILURE
     } from "./actions";

const initialState = {
    reviews: [],
    overview:null,
    loading: false,
    error: null,
};

export default function reviewReducer(state = initialState, action) {
    switch (action.type) {
        case CREATE_REVIEW_REQUEST:
            return {
                ...state,
                loading: true
            };
        case CREATE_REVIEW_SUCCESS:
            return {
                ...state,
                loading: false,
                reviews: [...state.reviews, action.payload]
            };
        case CREATE_REVIEW_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
            
        case GET_REVIEW_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_REVIEW_SUCCESS:
            return {
                ...state,
                loading: false,
                reviews: action.payload
            };
        case GET_REVIEW_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
            
        case DELETE_REVIEW_REQUEST:
            return {
                ...state,
                loading: true
            };
        case DELETE_REVIEW_SUCCESS:

        const updatedReviews = state.reviews.filter(review => review.id !== action.payload);
            return {
                ...state,
                loading: false,
                reviews: updatedReviews
            };
        case DELETE_REVIEW_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
            
        case GET_OVERVIEW_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_OVERVIEW_SUCCESS:
            return {
                ...state,
                loading: false,
                overview: action.payload
            };
        case GET_OVERVIEW_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
                        
        default:
            return state;
    }
}