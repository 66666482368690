const vendorData = [
  {
    _id: 'e628d8fc-130e-46da-9d2d-089406837a7c',
    shopName: 'Huel LLC',
    logo: 'https://i.ibb.co/m079wqz/logo-1.jpg',
    banner: 'http://dummyimage.com/100x100.png/dddddd/000000',
    cardBanner:'../Assets/images/vendorCardBanner/Image-1.jpg',
    rating: 2,
    totalReview: 22,
    city: 'Seoul',
    location: 'Albenia',
    longitude: 126.9779692,
    'postal code': 'G83',
    name: 'Datha Harmon',
    phone: '717-24-2429',
    email: 'dharmon0@latimes.com',
    hasOwnDelivery: true,
    isDeliveryFree: true,
    status: 'Open',
  },
  {
    _id: '8fb99109-ca7c-4a45-b6b1-5c0ea3279da6',
    shopName: 'Tremblay and Schiller',
    logo: 'https://i.ibb.co/hLx4PDp/logo-2.jpg',
    banner: 'http://dummyimage.com/100x100.png/ff4444/ffffff',
    cardBanner: require('../Assets/images/vendorCardBanner/Image-2.jpg')
      .default,
    rating: 3,
    totalReview: 22,
    city: 'Castlerea',
    location: 'Kretoria',
    longitude: -8.6346959,
    'postal code': 'F45',
    name: 'Hope Battisson',
    phone: '595-08-2102',
    email: 'hbattisson1@mac.com',
    hasOwnDelivery: false,
    isDeliveryFree: true,
    status: 'Open',
  },
  {
    _id: '4587978a-1734-4ad9-a2ab-484ea832822e',
    shopName: 'Fahey-Batz',
    logo: 'https://i.ibb.co/gTyP52V/logo-3.jpg',
    banner: 'http://dummyimage.com/100x100.png/dddddd/000000',
    cardBanner: require('../Assets/images/vendorCardBanner/Image-3.jpg')
      .default,
    rating: 1,
    totalReview: 22,
    city: 'Champerico',
    location: 'Kretoria',
    longitude: -91.912218,
    'postal code': '11007',
    name: 'Brandais Faull',
    phone: '506-84-9725',
    email: 'bfaull2@multiply.com',
    hasOwnDelivery: true,
    isDeliveryFree: true,
    status: 'Closed',
  },
  {
    _id: 'f40ef809-55c0-4632-b7ea-abb7e195782b',
    shopName: 'Baumbach LLC',
    logo: 'https://i.ibb.co/7W4Bcpg/logo-4.jpg',
    banner: 'http://dummyimage.com/100x100.png/ff4444/ffffff',
    cardBanner: require('../Assets/images/vendorCardBanner/Image-4.jpg')
      .default,
    rating: 3,
    totalReview: 22,
    city: 'Houxiang',
    location: 'Pretoria',
    longitude: 112.662673,
    'postal code': 'F11',
    name: 'Nataniel Durtnal',
    phone: '132-90-3868',
    email: 'ndurtnal3@tripod.com',
    hasOwnDelivery: true,
    isDeliveryFree: true,
    status: 'Open',
  },
  {
    _id: '57c7515d-e17d-4f6f-97cf-d1f35a336bed',
    shopName: 'Howell Group',
    logo: 'https://i.ibb.co/xCKt8dq/logo-5.jpg',
    banner: 'http://dummyimage.com/100x100.png/5fa2dd/ffffff',
    cardBanner: require('../Assets/images/vendorCardBanner/Image-5.jpg')
      .default,
    rating: 5,
    totalReview: 22,
    city: 'Abelheira',
    location: 'Porto',
    longitude: -8.5340895,
    'postal code': '4785-103',
    name: 'Archy Brew',
    phone: '279-16-6959',
    email: 'abrew4@nsw.gov.au',
    hasOwnDelivery: true,
    isDeliveryFree: false,
    status: 'Closed',
  },
  {
    _id: '5299f915-d6df-4970-a7b7-6b743f68cdf7',
    shopName: 'Williamson-Gerlach',
    logo: 'https://i.ibb.co/bmfZp95/logo-6.jpg',
    banner: 'http://dummyimage.com/100x100.png/dddddd/000000',
    cardBanner: require('../Assets/images/vendorCardBanner/Image-6.jpg')
      .default,
    rating: 4,
    totalReview: 22,
    city: 'Arrufó',
    location: 'Albaira',
    longitude: -61.7285757,
    'postal code': '6233',
    name: 'Dorisa McCulley',
    phone: '792-44-1782',
    email: 'dmcculley5@privacy.gov.au',
    hasOwnDelivery: false,
    isDeliveryFree: false,
    status: 'Open',
  },
  {
    _id: "e628d8fc-130e-46da-9d2d-086543837a7c",
    shopName: "Maul LLC",
    logo: "https://i.ibb.co/m079wqz/logo-1.jpg",
    banner: "http://dummyimage.com/100x100.png/dddddd/000000",
    cardBanner: require("../Assets/images/vendorCardBanner/Image-1.jpg").default,
    rating: 2,
    city: "Seoul",
    location: "Albenia",
    longitude: 126.9779692,
    "postal code": "G83",
    name: "Datha Harmon",
    phone: "717-24-2429",
    email: "dharmon0@latimes.com",
    hasOwnDelivery: true,
    isDeliveryFree: true,
    status: "Open"
  },
  {
    _id: "8fb99109-ca7c-4a45-b6b1-5c0ea8765da6",
    shopName: "Tremblay ",
    logo: "https://i.ibb.co/hLx4PDp/logo-2.jpg",
    banner: "http://dummyimage.com/100x100.png/ff4444/ffffff",
    cardBanner: require("../Assets/images/vendorCardBanner/Image-2.jpg").default,
    rating: 3,
    city: "Castlerea",
    location: "Kretoria",
    longitude: -8.6346959,
    "postal code": "F45",
    name: "Hope Battisson",
    phone: "595-08-2102",
    email: "hbattisson1@mac.com",
    hasOwnDelivery: false,
    isDeliveryFree: true,
    status: "Open"
  },
  {
    _id: "4587978a-1734-4ad9-a2ab-484ea833254e",
    shopName: "Lahey Yatz",
    logo: "https://i.ibb.co/gTyP52V/logo-3.jpg",
    banner: "http://dummyimage.com/100x100.png/dddddd/000000",
    cardBanner: require("../Assets/images/vendorCardBanner/Image-3.jpg").default,
    rating: 1,
    city: "Champerico",
    location: "Kretoria",
    longitude: -91.912218,
    "postal code": "11007",
    name: "Brandais Faull",
    phone: "506-84-9725",
    email: "bfaull2@multiply.com",
    hasOwnDelivery: true,
    isDeliveryFree: true,
    status: "Closed"
  },
];

export default vendorData;
