import React,{useEffect} from 'react';
import ClientReview from './ClientReview';
import { useDispatch, useSelector } from 'react-redux';
import {getAllReviewAction} from '../../../Redux/Review/actions';
import { useLocation } from 'react-router-dom';

const ClientReviewContainer = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const productId = searchParams.get('productId');
  const dispatch = useDispatch();
  const reviewsState = useSelector((state) => state.reviews);
  const {error, reviews, loading} = reviewsState;
  console.log(reviews)
  useEffect(() => {
    dispatch(getAllReviewAction(productId));
  }, [dispatch, productId]);

  return (
    <div>
      <h2 className="text-2xl pt-2 pl-10 font-display text-teal-600 font-medium tracking-wide">
        Client Reviews
      </h2>
      {
        loading && <p>Loading...</p>
      }
      {
        error && <p>{error}</p>
      }
      {
        reviews.length === 0 && <p>No reviews found.</p>
      }
      {
        reviews.map((review) => (
          <ClientReview key={review._id} review={review} />
        ))
      }
      {/* <ClientReview />
      <ClientReview />
      <ClientReview /> */}
    </div>
  );
};

export default ClientReviewContainer;
