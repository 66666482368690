import React from 'react';
import Header from '../../Components/Home/NavBar/Header';
import Footer from '../../Components/Home/Footer/Footer';
import NavBar from '../../Components/Home/NavBar/NavBar';
const TermsAndConditions = () => {
  return (
    <>
    <Header/>
    <NavBar/>
    <section
      style={{
        // maxWidth: '800px',
        margin: '20px auto',
        padding: '20px',
        backgroundColor: '#fff',
        // border: '1px solid #ddd',
        // borderRadius: '8px',
        // boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        fontFamily: 'Arial, sans-serif',
        lineHeight: '1.6',
        color: '#333',
      }}
    >
      <h1 style={{ textAlign: 'center', marginBottom: '20px', color: '#4a4a4a' }}>
        Terms and Conditions
      </h1>
      <p style={{ textAlign: 'center', color: '#777' }}>Last Updated: 03/09/2024</p>

      <p>
        Welcome to <strong>Baba Ki Booty</strong>. These Terms and Conditions outline the rules and
        regulations for the use of our website{' '}
        <a href="https://www.babakibooty.com/" style={{ color: '#1a73e8', textDecoration: 'none' }}>
          https://www.babakibooty.com/
        </a>
        . By accessing this website, you accept these terms and conditions. If you disagree with any
        part of the terms, please do not use our website.
      </p>

      <h3>1. General Terms</h3>
      <p>
        By accessing or using our website, you agree to comply with and be bound by these Terms and
        Conditions. These terms apply to all visitors, users, and others who access or use the
        website. Our products are only for personal use and are not intended for resale.
      </p>

      <h3>2. Purchases and Orders</h3>
      <p>
        When placing an order, you agree that you are authorized to use the payment method provided
        and that all information you provide is accurate and complete. We reserve the right to
        cancel or refuse any order at our discretion. You will be notified by email if your order is
        canceled.
      </p>

      <h3>3. Payment</h3>
      <p>
        All payments must be made through the payment methods available on our website. We are not
        responsible for any bank fees, charges, or exchange rates. All prices are listed in INR and
        include applicable taxes unless otherwise stated.
      </p>

      <h3>4. Shipping and Delivery</h3>
      <p>
        We aim to dispatch orders within the time frame specified at checkout. Delivery times are
        estimated and not guaranteed. We are not liable for any delays caused by factors outside of
        our control, such as customs processing or carrier delays.
      </p>

      <h3>5. Return and Refund Policy</h3>
      <p>
        If you are not satisfied with your purchase, please contact us within 7 days of receiving
        your order to request a return or refund. Products must be unused, unopened, and in their
        original packaging. Shipping fees are non-refundable.
      </p>

      <h3>6. Intellectual Property</h3>
      <p>
        All content on this website, including text, graphics, logos, images, and software, is the
        property of <strong>Baba Ki Booty</strong> or our licensors. You may not reproduce, distribute, or
        create derivative works from any content without our written permission.
      </p>

      <h3>7. Limitation of Liability</h3>
      <p>
        To the fullest extent permitted by law, <strong>Baba Ki Booty</strong> shall not be liable for any
        indirect, incidental, special, or consequential damages arising out of or in connection with
        the use of our website or products.
      </p>

      <h3>8. Governing Law</h3>
      <p>
        These terms shall be governed by and construed in accordance with the laws of India, without
        regard to its conflict of law principles. Any disputes arising out of or relating to these
        terms will be subject to the exclusive jurisdiction of the courts in Indore, Madhya Pradesh.
      </p>

      <h3>9. Changes to These Terms</h3>
      <p>
        We reserve the right to modify these Terms and Conditions at any time. Changes will be
        posted on this page with an updated date. Your continued use of the website following any
        changes indicates your acceptance of the new terms.
      </p>

      <h3>10. Contact Us</h3>
      <p>
        If you have any questions or concerns about these Terms and Conditions, please contact us
        at:
      </p>
      <ul style={{ marginLeft: '20px' }}>
        <li>
          <strong>Email:</strong> babakibooty0@gmail.com
        </li>
        <li>
          <strong>Phone:</strong> (+91) 9266804260
        </li>
        <li>
          <strong>Address:</strong> house no-857, uttam vihar, Rohtak, PO:Rohtak, DIST:Rohtak, Haryana, 124001
        </li>
      </ul>

      <p>Thank you for visiting <strong>Baba Ki Booty</strong>!</p>
    </section>
    <Footer/>
    </>
  );
};

export default TermsAndConditions;
