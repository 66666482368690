import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { loginAccount } from '../../../Redux/user/actions';
import FormValidation from '../../../Validation/FormValidation';
import Registration from '../Registration/Registration';
import './Login.css';
import {useNavigate} from 'react-router-dom'
const LoginForm = () => {
  const navigate = useNavigate();
  const [isAccount, setIsAccount] = useState(false);
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({});
  const [errorData, setErrorData] = useState({});

  const handelBlur = (e) => {
    //checking input value
    // const newErrorValue = FormValidation(e.target.name, e.target.value);
    // setErrorData((errorData) => ({ ...errorData, ...newErrorValue }));

    //setting input value in state
    setFormData((formData) => ({
      ...formData,
      [e.target.name]: e.target.value,
    }));
  };

  const recheckUserInput = () => {
    // checking input value on submit
    let newErrorObj = {};
    for (const key in formData) {
      const value = formData[key];
      const errorValue = FormValidation(key, value);
      newErrorObj = { ...newErrorObj, ...errorValue };
    }
    setErrorData(newErrorObj);
  };

  const login = (e) => {
    e.preventDefault();
    // recheckUserInput();
    dispatch(loginAccount(formData, navigate));
    const { email, password } =
      formData;
    // if (
    //   !firstName ||
    //   !lastName ||
    //   !userName ||
    //   !phone ||
    //   !email ||
    //   !password ||
    //   !cmPassword
    // ) {
    //   return openNotification("error", "All input value not given");
    // }

    // if (
    //   !errorData.firstName &&
    //   !errorData.lastName &&
    //   !errorData.userName &&
    //   !errorData.phone &&
    //   !errorData.email &&
    //   !errorData.password &&
    //   !errorData.cmPassword
    // ) {
    //   console.log(formData);
    //   openNotification("success", "User Sign Up Successful");
    // }
  };
  return (
    <>
      {!isAccount ? (
        <>
          <div className="relative min-h-screen flex">
            <div className="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-auto min-w-0 bg-white">
              <div
                className="sm:w-1/2 xl:w-3/5 h-full hidden md:flex flex-auto items-center justify-center p-10 overflow-hidden bg-teal-900 text-white bg-no-repeat bg-cover bg-center relative"
                style={{
                  backgroundImage:
                    'url(https://i.ibb.co/9hFrQqt/medicine-home-banner.png)',
                }}
              >
                <div className="absolute bg-gradient-to-b from-teal-600 to-teal-400 opacity-75 inset-0 z-0"></div>
                <div className="w-full max-w-md z-10">
                  <div className="sm:text-4xl font-semibold text-gray-100 leading-tight mb-6">
                    Your medication, delivered <br /> Say goodbye to all your
                    healthcare worries with us
                  </div>
                  <div className="sm:text-sm xl:text-md text-gray-200 font-normal">
                    What is Lorem Ipsum Lorem Ipsum is simply dummy text of the
                    printing and typesetting industry Lorem Ipsum has been the
                    industry's standard dummy text ever since the 1500s when an
                    unknown printer took a galley of type and scrambled it to
                    make a type specimen book it has?
                  </div>
                </div>

                <ul className="circles">
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                </ul>
              </div>
              <div className="md:flex md:items-center md:justify-center sm:w-auto md:h-full w-5/5 xl:w-2/5 p-8  md:p-10 lg:p-14 sm:rounded-lg md:rounded-none bg-white">
                <div className="max-w-md w-full space-y-8">
                  <div className="text-center">
                    <h2 className="mt-6 text-3xl font-semibold text-teal-600">
                      Welcom Back!
                    </h2>
                    <p className="mt-2 text-sm text-gray-500">
                      Please sign in to your account
                    </p>
                  </div>
                 
                  <form className="mt-8 space-y-6" action="#" method="POST">
                    <input type="hidden" name="remember" value="true" />
                    <div className="relative">
                      
                      <label className="ml-3 text-sm font-bold text-teal-500 tracking-wide">
                        Email
                      </label>
                      <input
                        className=" w-full text-base px-4 py-2 border-b border-gray-300 placeholder-gray-600 focus:outline-none rounded-2xl focus:border-teal-500"
                        type="email"
                        placeholder="mail@gmail.com"
                        name="email"
                        onBlur={(e)=>handelBlur(e)}
                      />
                    </div>
                    <div className="mt-8 content-center">
                      <label className="ml-3 text-sm font-bold text-teal-500 tracking-wide">
                        Password
                      </label>
                      <input
                        className="w-full content-center text-base px-4 py-2 border-b rounded-2xl border-gray-300 placeholder-gray-600 focus:outline-none focus:border-teal-500"
                        type="password"
                        placeholder="Enter your password"
                        name="password"
                        onBlur={(e)=>handelBlur(e)}
                      />
                    </div>
                    
                    <div>
                      <button
                        onClick={(e)=>login(e)}
                        type="submit"
                        className="w-full flex justify-center bg-gradient-to-r from-teal-500 to-teal-600  hover:bg-gradient-to-l hover:from-teal-600 hover:to-teal-500 text-gray-100 p-4  rounded-full tracking-wide font-semibold  shadow-lg cursor-pointer transition ease-in duration-500"
                      >
                        Sign in
                      </button>
                    </div>
                    <p className="flex flex-col items-center justify-center mt-10 text-center text-md text-gray-600">
                      <span>Don't have an account?</span>
                      <a
                        alt=""
                        href="#0"
                        onClick={() => setIsAccount((bool) => !bool)}
                        className="text-teal-500 hover:text-teal-700 text-lg font-semibold no-underline hover:underline cursor-pointer transition ease-in duration-300"
                      >
                        Sign up
                      </a>
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Registration setIsAccount={setIsAccount} />
      )}
    </>
  );
};

export default LoginForm;
