import React from 'react';
import Header from '../../Components/Home/NavBar/Header';
import NavBar from '../../Components/Home/NavBar/NavBar';
import Footer from '../../Components/Home/Footer/Footer';
const CancellationAndRefundPolicy = () => {
  return (
    <>
    <Header/>
    <NavBar/>
    <section
      style={{
        // maxWidth: '800px',
        margin: '20px auto',
        padding: '20px',
        backgroundColor: '#fff',
        // border: '1px solid #ddd',
        // borderRadius: '8px',
        // boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        fontFamily: 'Arial, sans-serif',
        lineHeight: '1.6',
        color: '#333',
      }}
    >
      <h1 style={{ textAlign: 'center', marginBottom: '20px', color: '#4a4a4a' }}>
        Cancellation and Refund Policy
      </h1>
      <p style={{ textAlign: 'center', color: '#777' }}>Last Updated: 03/09/2024</p>

      <p>
        At <strong>Baba Ki Booty</strong>, we strive to provide the best service and products to our
        customers. This Cancellation and Refund Policy outlines the terms under which you may
        cancel your order and request a refund. Please read this policy carefully before making a
        purchase.
      </p>

      <h3>1. Order Cancellation</h3>
      <p>
        You can cancel your order within 24 hours of placing it, provided that the order has not
        been dispatched. To cancel your order, please contact us immediately at{' '}
        <a href="mailto:babakibooty0@gmail.com" style={{ color: '#1a73e8', textDecoration: 'none' }}>
          babakibooty0@gmail.com
        </a>{' '}
        or call us at (+91) 9266804260. If your order has already been shipped, it cannot be
        canceled.
      </p>

      <h3>2. Return Policy</h3>
      <p>
        We accept returns of products within 7 days of delivery, provided that the items are
        unused, unopened, and in their original packaging. To initiate a return, please contact us
        with your order details. Returns will only be accepted if the products meet our criteria for
        return eligibility.
      </p>

      <h3>3. Non-Returnable Items</h3>
      <p>
        The following items are non-returnable and non-refundable:
      </p>
      <ul style={{ marginLeft: '20px' }}>
        <li>Opened or used products.</li>
        <li>Products purchased during sale or promotional events.</li>
        <li>Personalized or customized items.</li>
        <li>Products that are not in their original condition or packaging.</li>
      </ul>

      <h3>4. Refund Policy</h3>
      <p>
        Once your return is received and inspected, we will notify you of the status of your refund.
        If approved, your refund will be processed, and a credit will automatically be applied to
        your original method of payment within 7-10 business days. Please note that shipping fees
        are non-refundable.
      </p>

      <h3>5. Damaged or Defective Items</h3>
      <p>
        If you receive a damaged or defective product, please contact us within 48 hours of
        delivery. We may require photographic evidence of the damage or defect. We will provide a
        replacement or refund at our discretion.
      </p>

      <h3>6. Late or Missing Refunds</h3>
      <p>
        If you haven’t received a refund yet, first check your bank account again. Then contact your
        credit card company; it may take some time before your refund is officially posted. If
        you’ve done all of this and still have not received your refund, please contact us at{' '}
        <a href="mailto:babakibooty0@gmail.com" style={{ color: '#1a73e8', textDecoration: 'none' }}>
          babakibooty0@gmail.com
        </a>
        .
      </p>

      <h3>7. Contact Us</h3>
      <p>
        If you have any questions or need further assistance with your cancellation or refund, please contact us at:
      </p>
      <ul style={{ marginLeft: '20px' }}>
        <li>
          <strong>Email:</strong> babakibooty0@gmail.com
        </li>
        <li>
          <strong>Phone:</strong> (+91) 9266804260
        </li>
        <li>
          <strong>Address:</strong> House no-857, uttam vihar, Rohtak, PO:Rohtak, DIST:Rohtak, Haryana, 124001
        </li>
      </ul>

      <p>Thank you for shopping with <strong>Baba Ki Booty</strong>!</p>
    </section>
    <Footer/>
    </>
  );
};

export default CancellationAndRefundPolicy;
