import React,{useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {updateCart,deleteProductFromCart} from '../../Redux/Cart/actions'
import {getCartItems} from '../../Redux/Cart/actions'
import {createOrderAction} from '../../Redux/order/actions'
const Checkout = () => {
  const dispatch = useDispatch();
  const cartState = useSelector((state) => state.cart);
  const { cart, error, loading } = cartState;
  const updateQuantity = (productId, quantity) => {
    if(quantity>=1)
    dispatch(updateCart(productId, quantity));
  }
   const deleteItem = (productId) => {
    dispatch(deleteProductFromCart(productId));
  }
  const subtotal = cart?.reduce((total, item) => {
    return total + (item.productId.price * item.quantity);
  }, 0);
  const Shipping = 0;

  const [shippingInfo, setShippingInfo] = useState({
        customerName:"",
        phoneNumber:"",
        addressLine1:"",
        addressLine2:"",
        city:"",
        state:"",
        postalCode:"",
        country:"",
        street:"",
        houseNumber:null
  });

  const [paymentType, setPaymentType] = useState('cash');
 const handleChangeShippingInfo = (e) => {
  setShippingInfo({
    ...shippingInfo,
    [e.target.name] : e.target.value
 })
 }

 useEffect(()=>{
    dispatch(getCartItems());
 }, [dispatch])

const handleSubmit = () => {
  dispatch(createOrderAction(cart, shippingInfo, paymentType))
  setShippingInfo({ 
    customerName:"",
    phoneNumber:"",
    addressLine1:"",
    addressLine2:"",
    city:"",
    state:"",
    postalCode:"",
    country:"",
    street:"",
    houseNumber:null
  })
}

  return (
    <div className="flex justify-center my-6">
      <div className="flex flex-col w-full p-8 text-gray-800 bg-white pin-r pin-y md:w-4/5 lg:w-4/5">
        <div className="flex-1">
          <table className="w-full text-sm lg:text-base" cellspacing="0">
            <thead>
              <tr className="h-12 font-sans text-gray-800 text-lg border border-teal-300 border-t-0 border-l-0 border-r-0">
                <th className="hidden text-left md:table-cell font-medium text-teal-600">
                  Product Image
                </th>
                <th className="text-left font-medium text-teal-600">
                  Product Details
                </th>
                <th className="text-left pl-5 lg:pl-8">
                  <span className="inline font-medium text-teal-600">
                    Quantity
                  </span>
                </th>
                <th className="text-right lg:text-left md:table-cell font-medium text-teal-600">
                  Remove
                </th>
                <th className=" text-right md:table-cell font-medium text-teal-600">
                  Unit price
                </th>
                <th className="text-right font-medium text-teal-600">
                  Total price
                </th>
              </tr>
            </thead>
            <tbody>
            <tr>
                <td className="pt-2"> </td>
              </tr>
              {
                cart?.map((item)=>(
                  <>
                  <tr key={item._id}>
                <td className="hidden pb-4 md:table-cell">
                <Link to={`/productDetails?productId=${item.productId._id}`}>
                    <img
                      src={`data:image/jpeg;base64,${item.productId.image}`}
                      className="w-20 rounded"
                      alt="Product"
                    />
                  </Link>
                </td>
                <td>
                  <Link to={`/productDetails?productId=${item.productId._id}`}>
                  <a href="#0">
                    <p className="mb-2 ">{item.productId.name}</p>
                  </a>
                  </Link>
                </td>
                <td className="justify-center md:justify-end md:flex md:mt-6">
                  <div className="flex-1 flex items-end justify-between text-sm">
                    <div className="border border-gray-400 rounded">
                      <i className="fas fa-plus m-1 py-1 px-4 cursor-pointer font-medium text-teal-600" 
                      onClick={() => updateQuantity(item.productId._id, item.quantity+1)}></i>
                      <span>{item.quantity}</span>
                      <i className="fas fa-minus m-1 py-1 px-4 cursor-pointer font-medium text-teal-600"
                      onClick={() => updateQuantity(item.productId._id, item.quantity-1)}></i>
                    </div>
                  </div>
                </td>
                <td className="text-right px-5 md:table-cell">
                  <button style={{display:'flex', justifyContent:'center'}} onClick={()=>deleteItem(item.productId._id)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-gray-00"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                    />
                  </svg>
                  </button>
                </td>
                <td className=" text-right md:table-cell">
                  <span className="text-sm lg:text-base font-medium">
                  &#8377; {item.productId.price}
                  </span>
                </td>
                <td className="text-right">
                  <span className="text-sm lg:text-base font-medium">
                  &#8377; {item.productId.price * item.quantity}
                  </span>
                </td>
              </tr>
                  </>
                ))
              }
             
            </tbody>
          </table>
          <hr className="pb-6 mt-6" />
          <div className="my-4 mt-6 -mx-2 lg:flex">
            <div className="lg:px-2 lg:w-1/2">
       <div className="p-3 bg-teal-50 rounded-full">
                <h1 className="ml-2 font-semibold text-teal-600 uppercase">
                  Enter Shipping Info
                </h1>
              </div>
              <div className="px-4">
                <p className="mb-4 italic">
                  Enter your delivery address where you get the product. You can
                  also send any other location where you send the products.
                </p>
              </div>
              <form>
                <div className='flex' style={{justifyContent:'space-around'}}>
                <div className="mb-3 pt-0">
                  <input
                    type="text"
                    placeholder="Customer Name"
                    name="customerName"
                    value={shippingInfo.customerName}
                    onChange={handleChangeShippingInfo}
                    required='true'
                    className="px-3 py-4 my-1 border-b border-teal-300 placeholder-gray-500 text-blueGray-600 relative tracking-wide rounded-lg bg-white text-base shadow outline-none focus:outline-none focus:shadow-outline w-60"
                  />
                </div>
                <div className="mb-3 pt-0">
                  <input
                    type="number"
                    placeholder="Phone"
                    name='phoneNumber'
                    value={shippingInfo.phoneNumber}
                    onChange={handleChangeShippingInfo}
                    required='true'
                    className="px-3 py-4 my-1 border-b border-teal-300 placeholder-gray-500 text-blueGray-600 relative  rounded-lg bg-white text-base shadow outline-none focus:outline-none focus:shadow-outline w-60"
                  />
                </div>
                </div>
                <div className="mb-3 pt-0">
                  <input
                    type="text"
                    placeholder="Address Line 1"
                    name='addressLine1'
                    value={shippingInfo.addressLine1}
                    onChange={handleChangeShippingInfo}
                    required='true'
                    className="px-3 py-4 my-1 border-b border-teal-300 placeholder-gray-500 text-blueGray-600 relative  rounded-lg bg-white text-base shadow outline-none focus:outline-none focus:shadow-outline w-full"
                  />
                </div>
                <div className="mb-3 pt-0">
                  <input
                    type="text"
                    placeholder="Address Line 2"
                    value={shippingInfo.addressLine2}
                    onChange={handleChangeShippingInfo}
                    name='addressLine2'
                    className="px-3 py-4 my-1 border-b border-teal-300 placeholder-gray-500 text-blueGray-600 relative  rounded-lg bg-white text-base shadow outline-none focus:outline-none focus:shadow-outline w-full"
                  />
                </div>
                <div className='flex' style={{justifyContent:'space-around'}}>
                <div className="mb-3 pt-0">
                  <input
                    type="text"
                    placeholder="City"
                    name="city"
                    value={shippingInfo.city}
                    onChange={handleChangeShippingInfo}
                    required='true'
                    className="px-3 py-4 my-1 border-b border-teal-300 placeholder-gray-500 text-blueGray-600 relative tracking-wide rounded-lg bg-white text-base shadow outline-none focus:outline-none focus:shadow-outline w-60"
                  />
                </div>
                <div className="mb-3 pt-0">
                  <input
                    type="text"
                    placeholder="State"
                    name='state'
                    value={shippingInfo.state}
                    onChange={handleChangeShippingInfo}
                    required='true'
                    className="px-3 py-4 my-1 border-b border-teal-300 placeholder-gray-500 text-blueGray-600 relative  rounded-lg bg-white text-base shadow outline-none focus:outline-none focus:shadow-outline w-60"
                  />
                </div>
                </div>
                <div className='flex' style={{justifyContent:'space-around'}}>
                <div className="mb-3 pt-0">
                  <input
                    type="text"
                    placeholder="Postal Code"
                    name="postalCode"
                    value={shippingInfo.postalCode}
                    onChange={handleChangeShippingInfo}
                    required='true'
                    className="px-3 py-4 my-1 border-b border-teal-300 placeholder-gray-500 text-blueGray-600 relative tracking-wide rounded-lg bg-white text-base shadow outline-none focus:outline-none focus:shadow-outline w-60"
                  />
                </div>
                <div className="mb-3 pt-0">
                  <input
                    type="text"
                    placeholder="Country"
                    name='country'
                    value={shippingInfo.country}
                    onChange={handleChangeShippingInfo}
                    required='true'
                    className="px-3 py-4 my-1 border-b border-teal-300 placeholder-gray-500 text-blueGray-600 relative  rounded-lg bg-white text-base shadow outline-none focus:outline-none focus:shadow-outline w-60"
                  />
                </div>
                </div>
                <div className='flex' style={{justifyContent:'space-around'}}>
                <div className="mb-3 pt-0">
                  <input
                    type="text"
                    placeholder="Street"
                    name="street"
                    value={shippingInfo.street}
                    onChange={handleChangeShippingInfo}
                    required='true'
                    className="px-3 py-4 my-1 border-b border-teal-300 placeholder-gray-500 text-blueGray-600 relative tracking-wide rounded-lg bg-white text-base shadow outline-none focus:outline-none focus:shadow-outline w-60"
                  />
                </div>
                <div className="mb-3 pt-0">
                  <input
                    type="number"
                    placeholder="House Number"
                    name='houseNumber'
                    value={shippingInfo.houseNumber}
                    onChange={handleChangeShippingInfo}
                    required='true'
                    className="px-3 py-4 my-1 border-b border-teal-300 placeholder-gray-500 text-blueGray-600 relative  rounded-lg bg-white text-base shadow outline-none focus:outline-none focus:shadow-outline w-60"
                  />
                </div>
                </div>
              </form>
            </div>
            <div className="lg:px-2 lg:w-1/2">
              <div className="mb-4">
                <div className="p-3 bg-teal-50 rounded-full mb-3">
                  <h1 className="ml-2 font-semibold text-teal-600 uppercase">
                    Payment Method
                  </h1>
                </div>
                <div className="px-4">
                  <p className="mb-3 italic">
                    You can pay us in a multiple way in our payment gateway
                    system.
                  </p>

                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      className="form-radio h-5 w-5 text-gray-600"
                      name='paymentType'
                      value='cash'
                      onChange={()=>setPaymentType('cash')}
                      checked
                    />
                    <span className="ml-2 text-gray-800 font-medium">
                      Cash On Delivery
                    </span>
                  </label>
                  <hr />
                  <label className="inline-flex items-center mt-2">
                    <input
                      type="radio"
                      name='paymentType'
                      value='online'
                      onChange={()=>setPaymentType('online')}
                      className="form-radio h-5 w-5 text-gray-600"
                    />
                    <span className="ml-2 text-gray-800 font-medium">
                      Online
                    </span>
                  </label>
                </div>
              </div>

              <div className="p-3 bg-teal-50 rounded-full">
                <h1 className="ml-2 font-semibold text-teal-600 uppercase">
                  Order Details
                </h1>
              </div>
              <div className="p-4">
                <p className="mb-1 italic">
                  Shipping and additionnal costs are calculated based on values
                  you have entered
                </p>
                <div className="flex justify-between border-b">
                  <div className="lg:px-4 lg:py-2 m-1 text-lg lg:text-xl font-medium text-center text-gray-800">
                    Subtotal
                  </div>
                  <div className="lg:px-4 lg:py-2 m-1 lg:text-lg font-medium text-center text-gray-800">
                  &#8377;{subtotal}
                  </div>
                </div>
                
               
                <div className="flex justify-between pt-2 border-b">
                  <div className="lg:px-4 lg:py-2 m-1 text-lg lg:text-xl font-medium text-center text-gray-800">
                    Total
                  </div>
                  <div className="lg:px-4 lg:py-2 m-1 lg:text-lg font-medium text-center text-gray-800">
                  &#8377;{subtotal+Shipping}
                  </div>
                </div>
               
                  <button  className="flex justify-center w-full px-10 py-3 mt-6 font-medium text-white uppercase bg-teal-500 rounded-full shadow item-center hover:bg-teal-700 focus:shadow-outline focus:outline-none" onClick={handleSubmit}>
                    <svg
                      aria-hidden="true"
                      data-prefix="far"
                      data-icon="credit-card"
                      className="w-8"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                    >
                      <path
                        fill="currentColor"
                        d="M527.9 32H48.1C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48.1 48h479.8c26.6 0 48.1-21.5 48.1-48V80c0-26.5-21.5-48-48.1-48zM54.1 80h467.8c3.3 0 6 2.7 6 6v42H48.1V86c0-3.3 2.7-6 6-6zm467.8 352H54.1c-3.3 0-6-2.7-6-6V256h479.8v170c0 3.3-2.7 6-6 6zM192 332v40c0 6.6-5.4 12-12 12h-72c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h72c6.6 0 12 5.4 12 12zm192 0v40c0 6.6-5.4 12-12 12H236c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h136c6.6 0 12 5.4 12 12z"
                      />
                    </svg>
                    <span className="ml-2 pt-1">Place Order</span>
                  </button>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
