import React from 'react';

const NavLinks = () => {
  return (
    <>
      <li>
        <a
          href="/products"
          aria-label="Our product"
          title="Our product"
          className="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-teal-accent-700"
        >
          Shop
        </a>
      </li>
      <li>
        <a
          href="#contact"
          aria-label="Contact Us"
          title="Contact Us"
          className="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-teal-accent-700"
        >
          Contact Us
        </a>
      </li>
      <li>
        <a
          href="#about"
          aria-label="About"
          title="About"
          className="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-teal-accent-700"
        >
          About
        </a>
      </li>
     
      
    </>
  );
};

export default NavLinks;
