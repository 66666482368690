import { 
    CREATE_CATEGORY_REQUEST,
    CREATE_CATEGORY_SUCCESS,
    CREATE_CATEGORY_FAILURE, 
    GET_CATEGORY_REQUEST,
    GET_CATEGORY_SUCCESS,
    GET_CATEGORY_FAILURE,
    DELETE_CATEGORY_REQUEST,
    DELETE_CATEGORY_SUCCESS,
    DELETE_CATEGORY_FAILURE, 
     } from "./actions";

const initialState = {
    categories: [],
    loading: false,
    error: null
};

export default function categoryReducer(state = initialState, action) {
    switch (action.type) {
        case CREATE_CATEGORY_REQUEST:
            return {
                ...state,
                loading: true
            };
        case CREATE_CATEGORY_SUCCESS:
            return {
                ...state,
                loading: false,
                categories: [...state.categories, action.payload]
            };
        case CREATE_CATEGORY_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
            
        case GET_CATEGORY_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_CATEGORY_SUCCESS:
            return {
                ...state,
                loading: false,
                categories: action.payload
            };
        case GET_CATEGORY_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
            
        case DELETE_CATEGORY_REQUEST:
            return {
                ...state,
                loading: true
            };
        case DELETE_CATEGORY_SUCCESS:

        const updatedCategories = state.categories.filter(category => category.id !== action.payload);
            return {
                ...state,
                loading: false,
                categories: updatedCategories
            };
        case DELETE_CATEGORY_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
            
        default:
            return state;
    }
}