import {createReview, getAllReview, deleteReview, getOverview} from "../../Api";
import { showNotification } from "../notification/actions";

export const CREATE_REVIEW_REQUEST = "CREATE_REVIEW_REQUEST";
export const CREATE_REVIEW_SUCCESS = "CREATE_REVIEW_SUCCESS";
export const CREATE_REVIEW_FAILURE = "CREATE_REVIEW_FAILURE";

export const GET_REVIEW_REQUEST = "GET_REVIEW_REQUEST";
export const GET_REVIEW_SUCCESS = "GET_REVIEW_SUCCESS";
export const GET_REVIEW_FAILURE = "GET_REVIEW_FAILURE";

export const DELETE_REVIEW_REQUEST = "DELETE_REVIEW_REQUEST";
export const DELETE_REVIEW_SUCCESS = "DELETE_REVIEW_SUCCESS";
export const DELETE_REVIEW_FAILURE = "DELETE_REVIEW_FAILURE";

export const GET_OVERVIEW_REQUEST = "GET_OVERVIEW_REQUEST";
export const GET_OVERVIEW_SUCCESS = "GET_OVERVIEW_SUCCESS";
export const GET_OVERVIEW_FAILURE = "GET_OVERVIEW_FAILURE";

export const createReviewAction = (formData) => async (dispatch) => {
    try {
        const { data } = await createReview(formData);
        dispatch({ type: CREATE_REVIEW_SUCCESS, payload: data });
        dispatch(showNotification({ massageType: "success", massage: "Review created successfully." }));
    } catch (error) {
        dispatch(showNotification({ massageType: "error", massage: "Failed to create Review." }));
    }
};

export const getAllReviewAction = (productId) => async (dispatch) => {
    try {
        const { data } = await getAllReview(productId);
        console.log(data);
        dispatch({ type: GET_REVIEW_SUCCESS, payload: data });
    } catch (error) {
        dispatch(showNotification({ massageType: "error", massage: "Failed to get review." }));
    }
};

export const getOverviewAction = (productId) => async (dispatch) => {
    try {
        const { data } = await getOverview(productId);
        console.log(data);
        dispatch({ type: GET_OVERVIEW_SUCCESS, payload: data });
    } catch (error) {
        dispatch(showNotification({ massageType: "error", massage: "Failed to get overview." }));
    }
};

export const deleteReviewAction = (id) => async (dispatch) => {
    try {
        await deleteReview(id);
        dispatch({ type: DELETE_REVIEW_SUCCESS, payload: id });
        dispatch(showNotification({ massageType: "success", massage: "Review deleted successfully." }));
    } catch (error) {
        dispatch(showNotification({ massageType: "error", massage: "Failed to delete Review." }));
    }
};