import {
    ADD_CART_REQUEST,
    ADD_CART_SUCCESS,
    ADD_CART_FAILURE,
    GET_CART_REQUEST,
    GET_CART_SUCCESS,
    GET_CART_FAILURE,
    UPDATE_CART_REQUEST,
    UPDATE_CART_SUCCESS,
    UPDATE_CART_FAILURE,
    DELETE_CART_REQUEST,
    DELETE_CART_SUCCESS,
    DELETE_CART_FAILURE
} from './actions';

const initialState = {
    loading: false,
    cart: null,
    error: null
};

const cartReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_CART_REQUEST:
        case GET_CART_REQUEST:
        case UPDATE_CART_REQUEST:
        case DELETE_CART_REQUEST:
            return {
                ...state,
                loading: true
            };

        case GET_CART_SUCCESS:
            return {
                ...state,
                loading: false,
                cart: action.payload,
                error: null
            };
            case UPDATE_CART_SUCCESS: {
                const updatedItems = state.cart.map(item => 
                    item.productId._id === action.payload.productId
                        ? { ...item, quantity: action.payload.quantity }
                        : item
                );
                return {
                    ...state,
                    loading: false,
                    cart:  updatedItems,
                    error: null
                };
            }
            case DELETE_CART_SUCCESS: {
                const updatedItems = state.cart.filter(
                    item => item.productId._id !== action.payload
                );
                return {
                    ...state,
                    loading: false,
                    cart: updatedItems,
                    error: null
                };
            }
        case ADD_CART_FAILURE:
        case GET_CART_FAILURE:
        case UPDATE_CART_FAILURE:
        case DELETE_CART_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        default:
            return state;
    }
};

export default cartReducer;
