import React, { useState, useEffect } from 'react';
import EditOrders from './EditOrders';
import { fetchOrders } from '../../../../Redux/order/actions';
import { useDispatch, useSelector } from 'react-redux';

const AllOrders = () => {
  const [editModal, setEditModal] = useState(false);
  const [viewOrder, setViewOrder] = useState();
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [dateFilter, setDateFilter] = useState('');
  const [priceFilter, setPriceFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [ordersPerPage] = useState(5);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchOrders());
  }, [dispatch]);

  const { orders, loading, error } = useSelector((state) => state.order);

  // Filtered and paginated orders
  const filteredOrders = orders
    .filter((order) => {
      const matchesSearchTerm = searchTerm === '' || order.shippingInfo.customerName.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesStatus = statusFilter === '' || order.status === statusFilter;
      const matchesDate = dateFilter === '' || new Date(order.createdAt).toLocaleDateString() === new Date(dateFilter).toLocaleDateString();
      const matchesPrice = priceFilter === '' || order.totalPrice.toString().includes(priceFilter);
      return matchesSearchTerm && matchesStatus && matchesDate && matchesPrice;
    });

  // Pagination logic
  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = filteredOrders.slice(indexOfFirstOrder, indexOfLastOrder);

  const totalPages = Math.ceil(filteredOrders.length / ordersPerPage);

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  const handleSearch = (e) => {
    e.preventDefault();
    setCurrentPage(1);
  };

  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  const handleView = (order) => {
    setViewOrder(order);
    setEditModal(true);
  }
  return (
    <section>
      <div className="container mx-auto px-4 sm:px-8 max-w-full sm:max-w-5xl">
        <div className="flex flex-row mb-1 sm:mb-0 " style={{display:'flex', justifyContent:'center'}}>
          
          <div className="text-end">
            <form className="flex flex-col md:flex-row w-3/4 md:w-full max-w-sm md:space-x-3 space-y-3 md:space-y-0 justify-center" onSubmit={handleSearch}>
              <input
                type="text"
                className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-50 py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-teal-600 focus:border-transparent"
                placeholder="User Name"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <input
                type="date"
                className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-teal-600 focus:border-transparent"
                value={dateFilter}
                onChange={(e) => setDateFilter(e.target.value)}
              />
              <input
                type="text"
                className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-50 py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-teal-600 focus:border-transparent"
                placeholder="Price"
                value={priceFilter}
                onChange={(e) => setPriceFilter(e.target.value)}
              />
              <select
                className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-50 py-2 px-4 bg-white text-gray-700 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-teal-600 focus:border-transparent"
                value={statusFilter}
                onChange={(e) => setStatusFilter(e.target.value)}
                
              >
                <option value="">All Status</option>
                <option value="pending">Pending</option>
                <option value="processing">Processing</option>
                <option value="shipped">Shipped</option>
                <option value="delivered">Delivered</option>
                <option value="cancelled">Cancelled</option>

              </select>
              <button
                className="flex-shrink-0 px-4 py-2 text-base font-medium tracking-wide text-white bg-teal-500 rounded-lg shadow-md hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2 focus:ring-offset-teal-200"
                type="submit"
              >
                Filter
              </button>
            </form>
          </div>
        </div>

        {/* Orders Table */}
        <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
          <div className="inline-block min-w-full shadow-3xl rounded-lg overflow-hidden">
            <table className="min-w-full leading-normal font-sans tracking-wide">
            <thead>
                 <tr>
                   <th
                    scope="col"
                    className="px-5 pb-3 pt-4 bg-teal-100 border-b border-gray-200 text-gray-800  text-center text-sm uppercase font-semibold"
                  >
                    User Info
                  </th>
                  <th
                    scope="col"
                    className="px-5 pb-3 pt-4 bg-teal-100 border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-semibold"
                  >
                    Address
                  </th>
                  <th
                    scope="col"
                    className="px-5 pb-3 pt-4 bg-teal-100 border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-semibold"
                  >
                    Products
                  </th>
                  <th
                    scope="col"
                    className="px-5 pb-3 pt-4 bg-teal-100 border-b border-gray-200 text-gray-800  text-center text-sm uppercase font-semibold"
                  >
                    Order Date
                  </th>
                  <th
                    scope="col"
                    className="px-5 pb-3 pt-4 bg-teal-100 border-b border-gray-200 text-gray-800  text-center text-sm uppercase font-semibold"
                  >
                    Price
                  </th>
                  <th
                    scope="col"
                    className="px-5 pb-3 pt-4 bg-teal-100 border-b border-gray-200 text-gray-800  text-left pl-12 text-sm uppercase font-semibold"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="px-5 pb-3 pt-4 bg-teal-100 border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-semibold"
                  >
                    Action
                  </th>
                </tr>
              </thead>
             
                {currentOrders.map((order) => {
                  return (
                    <>
                       
                                      <tbody key={order._id}>
                                        <tr>
                                          <td className="px-5 py-5 border-b border-gray-200 bg-white font-body font-medium text-sm">
                                            <div className="flex items-center w-24 sm:w-full">
                                              
                                              <div className="ml-3">
                                                <p className="text-gray-900 whitespace-no-wrap">
                                                  {order.shippingInfo.customerName}
                                                </p>
                                              </div>
                                            </div>
                                          </td>
                                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                            <p className="text-gray-900 whitespace-no-wrap">
                                              {order.shippingInfo.addressLine1}
                                            </p>
                                          </td>
                                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-center text-sm">
                                            <p className="text-gray-900 whitespace-no-wrap">
                                              {order.items.length}
                                            </p>
                                          </td>
                                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                            <p className="text-gray-900 whitespace-no-wrap">
                                              {new Date(order.createdAt).toLocaleDateString('en-US', options)}
                                            </p>
                                          </td>
                                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                            <p className="text-gray-900 whitespace-no-wrap">
                                            &#8377; {order.totalPrice}
                                            </p>
                                          </td>
                                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm ">
                                            <span
                                              className={`relative inline-block px-3 py-1 w-28 text-center font-semibold leading-tight ${
                                                order.status === 'pending'
                                                  ? 'text-yellow-900'
                                                  : order.status === 'confirmed'
                                                  ? 'text-indigo-600'
                                                  : order.status === 'completed'
                                                  ? 'text-green-800'
                                                  : order.status === 'cancelled'
                                                  ? 'text-red-500'
                                                  : 'text-blue-800'
                                              }`}
                                            >
                                              <span
                                                aria-hidden="true"
                                                className={`absolute inset-0 opacity-50 rounded-full ${
                                                  order.status === 'pending'
                                                    ? 'bg-yellow-600'
                                                    : order.status === 'confirmed'
                                                    ? 'bg-indigo-200'
                                                    : order.status === 'completed'
                                                    ? 'bg-green-200'
                                                    : order.status === 'cancelled'
                                                    ? 'bg-red-200'
                                                    : 'bg-blue-300'
                                                }`}
                                              ></span>
                                              <span className="relative">{order.status}</span>
                                            </span>
                                          </td>
                                          <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                            <button
                                              className="flex align-center justify-center font-body font-semibold text-teal-600 hover:text-teal-900"
                                              onClick={()=>handleView(order) }
                                            >
                                             <img className='w-5 h-5' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAACXBIWXMAAAsTAAALEwEAmpwYAAAE2klEQVR4nO1X609bZRjvF436UT8a5z/gBxMviYyoibODjt6QLuKi+6AGvHxgSDRxCdeZkVWju6V20HPKhHFJyxJDXMs5h3bTtFEZLRfLGKVjZhSwF0o5LZRSHvMcV9LLOaV0bPFDf8mTvHnP+77P77zP+9xEoiKKKKKI/ycGBlSPm7Xl5YxeccFyWTVO6xUrwzrJJgqOcQ6/UR3islHtS489MmKU9tABhpBfoAkZa7/6ecjj7E8EvE6IRYOQ2IpxguPAghM8jv6EffCzEEVI12hCcc5EiJ97eMS63nqGIqQampBtzNgvxdbXliFfRMNLcMv2Y4zWSTdoUnbR1CF+el/JDeskR2lCtuowN0dRWaHYYP0wZf2WpYiKsLlDUvPAxCzkG0/QpKzves+xyOo/M7xKx2774HvDBHx4xgJVTSZOPlJb4QfjBDhmfbx7Qsu3wNpzLMrolX2ooyBytObIsyOkcmrM1MTGY2yWkns+Fr7Q2OB4uxVO6KahxbgI7UNBTnCMc/itQWODBV/2/vhmFMbpbyIMqZxhOiXP74kcpS17hSbkwfnJq1t8NzDpCcB7bRTUk9OgvrYC6mshXjnzywrUE9NQ3UbB1J0A723OTwxuoS7UmRc5k1ZcQpPy8KLbus13IN4cKjzZd1eQmHGUhbX1BITXE2AYZeHr3rvcDy34s28SseT5bZsmFWGq8/BrOcmZOyWvM6SCDXrHQQho1v9uLiQoSCwJHONcvc4FDRq74LmBBQegbkpbVipA7u0X8OZ8f/8heAg+enxXaLpUQi39HlDWtEKJWMWJP7iaRRD3fHDaAk63X/B8/72bQJPyNfMlyYsZb+7QAZqUBRbnbvCaNQn01nrClUWutKwaXn1TuiN1XzXDsi8Aq5E4GP5kd9ae6HTBWcNkLhWAT4sh5QHkxJFDN2dIpct9s3sz504ALpS0GL1pBJU1rWnkUqWyti1tbbPBy4Wg3eAevRxnSMXtn7UVT4lGSOUVh7kxApDz8jhUNZq4MJKqtESsEiR4UHw0bW37UIA7Y3dswzh9CuOkQTSiV/bsjWAgf4KH0wmeHgqAqsmcJ8E2ltFXdqeY+KfCTPxxsyDBd2pPFWTi2dEuzsS271RPZjjJ9V2d5IQu3Ukae6bhYNm7WeRKy6uhdcCTtrYOncSY20m86CSEzL/jJNlh5nfBzU63jwsVfGGmsraNe3MoeHOZ5HDP++0WGJ/bJcwQsuwwkxmosZYTAuZWTF+5ArWaR+p10/Cl1r5rDBQM1EkMd5a/zJCKVe+shdfcC/dTHaYvdZ7kTvbOc6nO64/wklvy/JpfqksvFmQrmMj5DsTEzxULOleWuTPNilUN/tBfAsXCnQnjFurKu1hILbcYvWJyzNTIW25h4m/Q2Lk3iRkCPTRZbuG4Tufi3hyale/m4psRjHWFlVtJYAiiCGmvtaeaxSKTD063n/NMDB8YJ1FwjHNCDhFadoG1uzpCk/IrBResAiV/5EFK/nXWB5NW9Rqlk65QHUeOi/YT2OjQOulFbHxm7NpYNLyYNzFcm2yaKF3F+X1vmlLBBXVCcQ5bSdvgpyGPoy+BtdxGxA+JeIwTHOPcnKM3YTN+cr/tlJ99qG1nJrAZN2PjTsrPZzbuDKkIWrqqnNyPPOrGvYgiiihCtCf8CzT3cE2qpKksAAAAAElFTkSuQmCC"/>
                                              View
                                            </button>
                                          </td>
                                        </tr>
                                       
                                      </tbody>
                                      </>
                                    );
                                  }
                )}
             
            </table>
          </div>

          {/* Pagination Controls */}
          <div className="flex justify-center mt-4">
            {[...Array(totalPages)].map((_, index) => (
              <button
                key={index}
                className={`mx-1 px-3 py-1 rounded ${
                  currentPage === index + 1 ? 'bg-teal-500 text-white' : 'bg-gray-200 text-gray-700'
                }`}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </div>
      </div>
       {/* Edit Modal Component */}
       {editModal ? (
                            <EditOrders
                              setEditModal={setEditModal}
                              order={viewOrder}
                            />
                          ) : null}
    </section>

  );
};

export default AllOrders;
