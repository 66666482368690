import notificationReducer from "./notification/reducers";
import settingReducer from "./setting/reducer";
import userReducer from "./user/reducers";
import productReducer from "./product/reducer";
import categoryReducer from "./category/reducers";
import reviewReducer from "./Review/reducers";
import cartReducer from "./Cart/reducers";
import orderReducer from "./order/reducers";
import messageReducer from "./messages/reducers";
// const { combineReducers } = require("redux");

import { combineReducers } from 'redux';

export const rootReducer = combineReducers(
    {
        user:userReducer,
        setting:settingReducer,
        notification:notificationReducer,
        products:productReducer,
        categories:categoryReducer,
        reviews:reviewReducer,
        cart: cartReducer,
        order:orderReducer,
        messages: messageReducer
    }
)
