import { 
    FETCH_PRODUCT_REQUEST, FETCH_PRODUCT_SUCCESS, FETCH_PRODUCT_FAILURE, 
    CREATE_PRODUCT_REQUEST, CREATE_PRODUCT_SUCCESS, CREATE_PRODUCT_FAILURE,
    UPDATE_PRODUCT_REQUEST, UPDATE_PRODUCT_SUCCESS, UPDATE_PRODUCT_FAILURE,
    DELETE_PRODUCT_REQUEST, DELETE_PRODUCT_SUCCESS, DELETE_PRODUCT_FAILURE,
    FETCH_PRODUCTBYID_REQUEST, FETCH_PRODUCTBYID_SUCCESS, FETCH_PRODUCTBYID_FAILURE, 
    FETCH_ALL_PRODUCTS_SUCCESS
} from "./action";

const initialState = {
    products: [],
    allProducts:[],
    product: null,
    loading: false,
    error: null
};

export default function productReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_PRODUCT_REQUEST:
        case FETCH_PRODUCTBYID_REQUEST:
        case CREATE_PRODUCT_REQUEST:
        case UPDATE_PRODUCT_REQUEST:
        case DELETE_PRODUCT_REQUEST:
            return {
                ...state,
                loading: true
            };
        
        case FETCH_PRODUCT_SUCCESS:
            return {
                ...state,
                loading: false,
                products: action.payload
            };
            case FETCH_ALL_PRODUCTS_SUCCESS:
            return {
                ...state,
                loading: false,
                allProducts: action.payload
            };
        case FETCH_PRODUCTBYID_SUCCESS:
            return {
                ...state,
                loading: false,
                product: action.payload
            };
        
        case CREATE_PRODUCT_SUCCESS:
            return {
                ...state,
                loading: false,
                products: [...state.products, action.payload]
            };
        
        case UPDATE_PRODUCT_SUCCESS:
            return {
                ...state,
                loading: false,
                products: state.products.map(product =>
                    product.id === action.payload.id ? action.payload : product
                )
            };
        
        case DELETE_PRODUCT_SUCCESS:
            return {
                ...state,
                loading: false,
                products: state.products.filter(product => product.id !== action.payload)
            };
        
        case FETCH_PRODUCT_FAILURE:
        case CREATE_PRODUCT_FAILURE:
        case UPDATE_PRODUCT_FAILURE:
        case DELETE_PRODUCT_FAILURE:
        case FETCH_PRODUCTBYID_FAILURE:

            return {
                ...state,
                loading: false,
                error: action.payload
            };
        
        default:
            return state;
    }
}
