import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers } from '../../../../Redux/user/actions';

const AllCustomers = () => {
  const dispatch = useDispatch();
  const usersState = useSelector((state) => state.user);
  const { users } = usersState;

  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const usersPerPage = 15; // Number of users per page

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  // Filter users based on the search term
  useEffect(() => {
    const searchFilter = users?.filter((user) =>
      user.userName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredUsers(searchFilter);
  }, [searchTerm, users]);

  // Calculate total pages
  const totalPages = Math.ceil(filteredUsers?.length / usersPerPage);

  // Get current users for the current page
  const currentUsers = filteredUsers?.slice(
    (currentPage - 1) * usersPerPage,
    currentPage * usersPerPage
  );

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to the first page when searching
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };

  return (
    <section>
      <div className="container mx-auto px-4 sm:px-8 max-w-full sm:max-w-5xl">
        <div className="flex flex-row mb-1 sm:mb-0 justify-between w-full">
          <h2 className="font-display text-teal-700 text-2xl leading-tight">
            Customers Data
          </h2>
          <div className="text-end">
            <form className="flex flex-col md:flex-row w-3/4 md:w-full max-w-sm md:space-x-3 space-y-3 md:space-y-0 justify-center">
              <div className="relative">
                <input
                  type="text"
                  id="form-subscribe-filter"
                  className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-teal-600 focus:border-transparent"
                  placeholder="User Name"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </div>
            </form>
          </div>
        </div>

        {/* User Table */}
        <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
          <div className="inline-block min-w-full shadow-3xl rounded-lg overflow-hidden">
            <table className="min-w-full leading-normal font-sans tracking-wide">
              <thead>
                <tr>
                  <th className="px-5 pb-3 pt-4 bg-teal-100 border-b border-gray-200 text-gray-800 text-center text-sm uppercase font-semibold">User Info</th>
                  <th className="px-5 pb-3 pt-4 bg-teal-100 border-b border-gray-200 text-gray-800 text-left text-sm uppercase font-semibold">Email</th>
                  <th className="px-5 pb-3 pt-4 bg-teal-100 border-b border-gray-200 text-gray-800 text-center text-sm uppercase font-semibold">Phone</th>
                  <th className="px-5 pb-3 pt-4 bg-teal-100 border-b border-gray-200 text-gray-800 text-center text-sm uppercase font-semibold">Register Date</th>
                </tr>
              </thead>
              <tbody>
                {currentUsers?.map((user) => (
                  <tr key={user._id}>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white font-body font-medium text-sm">
                      <div className="flex items-center w-24 sm:w-full">
                        <div className="ml-3">
                          <p className="text-gray-900 whitespace-no-wrap">{user.userName}</p>
                        </div>
                      </div>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">{user.email}</p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">{user.phone}</p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">
                        {new Date(user.createdAt).toLocaleDateString('en-US', options)}
                      </p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Pagination */}
            <div className="px-5 bg-white py-5 flex flex-col xs:flex-row items-center xs:justify-between">
              <div className="flex items-center">
                <button
                  type="button"
                  className={`w-full p-4 border text-base rounded-l-xl text-gray-600 bg-white hover:bg-gray-100 ${currentPage === 1 ? 'cursor-not-allowed opacity-50' : ''}`}
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                {Array.from({ length: totalPages }, (_, index) => (
                  <button
                    key={index + 1}
                    type="button"
                    className={`w-full px-4 py-2 border-t border-b text-base ${currentPage === index + 1 ? 'text-teal-500 bg-white hover:bg-teal-100' : 'text-gray-600 bg-white hover:bg-teal-50'}`}
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </button>
                ))}
                <button
                  type="button"
                  className={`w-full p-4 border-t border-b border-r text-base rounded-r-xl text-gray-600 bg-white hover:bg-teal-50 ${currentPage === totalPages ? 'cursor-not-allowed opacity-50' : ''}`}
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AllCustomers;
