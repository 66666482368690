import { Add_LOGIN_USER, LOGOUT, GET_ALL_USERS } from "./actions";

const initialState = {
  token: null,
  userInfo: {
    id: "",
    name: "",
    email: "",
    isAdmin: false,
  },
  users:[]
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case Add_LOGIN_USER:
      localStorage.setItem("profile", JSON.stringify(action.payload));
      return { ...state, ...action.payload };

    case LOGOUT:
      localStorage.setItem("profile", JSON.stringify(null));
      return {
        token: null,
      };
      
    case GET_ALL_USERS:
      return {
       ...state,
        users: action.payload,
      };
           
      
    case "SET_USER_INFO":
    default:
      const profile = JSON.parse(localStorage.getItem("profile")||'{}');
      return profile;
  }
}
