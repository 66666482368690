import {
    FETCH_ORDERS_REQUEST, FETCH_ORDERS_SUCCESS, FETCH_ORDERS_FAILURE,
    CREATE_ORDER_REQUEST, CREATE_ORDER_SUCCESS, CREATE_ORDER_FAILURE,
    UPDATE_ORDER_STATUS_REQUEST, UPDATE_ORDER_STATUS_SUCCESS, UPDATE_ORDER_STATUS_FAILURE,
    UPDATE_ITEM_STATUS_REQUEST, UPDATE_ITEM_STATUS_SUCCESS, UPDATE_ITEM_STATUS_FAILURE,
    FETCH_ORDER_BY_ID_REQUEST, FETCH_ORDER_BY_ID_SUCCESS, FETCH_ORDER_BY_ID_FAILURE,
    FETCH_ORDERS_BY_USER_REQUEST, FETCH_ORDERS_BY_USER_SUCCESS, FETCH_ORDERS_BY_USER_FAILURE
} from "./actions";

const initialState = {
    orders: [],
    order: null,
    loading: false,
    error: null
};

export default function orderReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_ORDERS_REQUEST:
        case FETCH_ORDER_BY_ID_REQUEST:
        case FETCH_ORDERS_BY_USER_REQUEST:
        case CREATE_ORDER_REQUEST:
        case UPDATE_ORDER_STATUS_REQUEST:
        case UPDATE_ITEM_STATUS_REQUEST:
            return {
                ...state,
                loading: true
            };

        case FETCH_ORDERS_SUCCESS:
            return {
                ...state,
                loading: false,
                orders: action.payload
            };
        case FETCH_ORDER_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                order: action.payload
            };
        case FETCH_ORDERS_BY_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                orders: action.payload
            };
        case CREATE_ORDER_SUCCESS:
            return {
                ...state,
                loading: false,
                orders: [...state.orders, action.payload]
            };
        case UPDATE_ORDER_STATUS_SUCCESS:
        case UPDATE_ITEM_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                orders: state.orders.map(order =>
                    order._id === action.payload._id ? action.payload : order
                )
            };

        case FETCH_ORDERS_FAILURE:
        case FETCH_ORDER_BY_ID_FAILURE:
        case FETCH_ORDERS_BY_USER_FAILURE:
        case CREATE_ORDER_FAILURE:
        case UPDATE_ORDER_STATUS_FAILURE:
        case UPDATE_ITEM_STATUS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        default:
            return state;
    }
}
