import React from 'react';
import { FaStar } from 'react-icons/fa';

const ClientReview = ({ review }) => {
  const rating = review?.rating || 0; // Get rating from review object or default to 0

  return (
    <div className="container flex flex-col w-full px-6 py-4 mx-auto divide-y rounded-md divide-coolGray-700">
      <div className="flex justify-between p-4 font-sans">
        <div className="flex space-x-4">
          <div>
            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAABtklEQVR4nO2ZPShFYRjHf5dEsriSrAwWGViUkiQMRh8Li7JZZVDko5QySBYGCzIZZRZyDcrHjUSxiluyuK7u0VvnLqeLc895OM/N+dV/fHuf3zmn932f80JISEhQ1ABDwBywAiwBE0ALECEP6ASOAeubHABRFDMPpH+QyOQBWAUaUMaoSwFn3oFhlFAOvHgUMUkB9Shg0IdEJmsoYFNA5BoFxAREPoDioEVuBERMyoIWuRUSKQ1a5EhAwqx6gbMjIHKKAqYFRJZRQI+ASC8KaBQQaUIBewIiuyjgUkDkAgVsCYhsoICOHPqQbDFj21FCtw+RLpRx50HCHG/UsehBxIxRRy2QzLEzrEMps/n+NjJEXO4r90Ahyom5EImTB5y4ELkiD4i7/EGnmiLg0YXIK1CCYqZyWLUWUEgUWPdw5jL/xCpRQBUw6fJz+ipPwAxQHYRAq/0033wIOJMEtoG2375DKbB76zPB4r/KOdBvzylK8x8JWFmEzNwijNgHPCugpOwafDHgs/uTShro83OB86xAwrKTACq8iIwrKN5yZMyLyKGCwi1H9r2IJBQUbmXZPENCQv4rn6Ej5y2yxDtPAAAAAElFTkSuQmCC"/>
          </div>
          <div>
            <h4 className="font-display font-semibold text-gray-900">
              {review?.userId?.firstName} {review?.userId?.lastName}
            </h4>
            <div className="flex space-x-1">
              {[...Array(5)].map((_, index) => (
                 <FaStar
                 key={index}
                 color={index < rating ? "gold" : "gray"}
               />
              ))}
            </div>
            <span className="text-xs text-gray-800 tracking-wide">
              {review?.creeatedAt}
            </span>
          </div>
        </div>
      </div>
      <div className="p-4 space-y-2 text-sm font-sans text-gray-800 tracking-wide">
        <p>{review?.comment}</p>
      </div>
    </div>
  );
};
export default ClientReview;
