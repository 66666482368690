import {addToCart, getCart, updateCartQuantity, deleteFromCart} from '../../Api/index';

import { showNotification } from "../notification/actions";

export const ADD_CART_REQUEST = "ADD_CART_REQUEST";
export const ADD_CART_SUCCESS = "ADD_CART_SUCCESS";
export const ADD_CART_FAILURE = "ADD_CART_FAILURE";

export const GET_CART_REQUEST = "GET_CART_REQUEST";
export const GET_CART_SUCCESS = "GET_CART_SUCCESS";
export const GET_CART_FAILURE = "GET_CART_FAILURE";

export const DELETE_CART_REQUEST = "DELETE_CART_REQUEST";
export const DELETE_CART_SUCCESS = "DELETE_CART_SUCCESS";
export const DELETE_CART_FAILURE = "DELETE_CART_FAILURE";

export const UPDATE_CART_REQUEST = "UPDATE_CART_REQUEST";
export const UPDATE_CART_SUCCESS = "UPDATE_CART_SUCCESS";
export const UPDATE_CART_FAILURE = "UPDATE_CART_FAILURE";

export const addProductToCart = (productId, quantity) => async (dispatch) => {
    dispatch({ type: ADD_CART_REQUEST });
    try {
        const { data } = await addToCart(productId, quantity);
        dispatch({ type: ADD_CART_SUCCESS, payload: data });
        dispatch(showNotification({ message: "Product added to cart", massageType: "success" }));
    } catch (error) {
        dispatch(showNotification({ massageType: "error", massage: "Failed to add to cart." }))
        dispatch({ type: ADD_CART_FAILURE, payload: error.message });
    }
};

export const getCartItems = () => async (dispatch) => {
    dispatch({ type: GET_CART_REQUEST });
    try {
        const { data } = await getCart();
        dispatch({ type: GET_CART_SUCCESS, payload: data.cart.items });
    } catch (error) {
        // dispatch(showNotification({ massageType: "error", massage: "Failed to fetch cart items." }))
        dispatch({ type: GET_CART_FAILURE, payload: error.message });
    }
};

export const updateCart = (productId, quantity) => async (dispatch) => {
    dispatch({ type: UPDATE_CART_REQUEST });
    try {
        const { data } = await updateCartQuantity(productId, quantity);
        dispatch({ type: UPDATE_CART_SUCCESS, payload: {productId,quantity} });
        dispatch(showNotification({ message: "Cart updated", massageType: "success" }));
    } catch (error) {
        dispatch(showNotification({ massageType: "error", massage: "Failed to update cart." }))
        dispatch({ type: UPDATE_CART_FAILURE, payload: error.message });
    }
};

export const deleteProductFromCart = (productId) => async (dispatch) => {
    dispatch({ type: DELETE_CART_REQUEST });
    try {
        await deleteFromCart(productId);
        dispatch({ type: DELETE_CART_SUCCESS, payload: productId });
        dispatch(showNotification({ message: "Product removed from cart", massageType: "success" }));
    } catch (error) {
        dispatch(showNotification({ massageType: "error", massage: "Failed to remove product from cart." }))
        dispatch({ type: DELETE_CART_FAILURE, payload: error.message });
    }
};