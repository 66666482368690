// import React, { useState } from 'react';
// import { useLocation } from 'react-router-dom';
// import { useSelector, useDispatch } from 'react-redux';

// import { createReviewAction } from '../../Redux/Review/actions';
// const ReviewTaker = () => {
//   const location = useLocation();
//   const searchParams = new URLSearchParams(location.search);
//   const productId = searchParams.get('productId');
//   const dispatch = useDispatch();
//   const [formData,setFormData] = useState({});
//   const [rating, setRating] = useState(1);
//   const handleChange = (e) => {
//     setFormData({...formData,[e.target.name]:e.target.value});
//   }
//   const handleSubmit = (e) => {
//     //add rating and productId in formData
//     const reviewData = {...formData,rating,productId};
//     dispatch(createReviewAction(reviewData));
//     //clear form data
//     setFormData({});
//     setRating(0);
    
//   }
//   return (
//     <div className="flex flex-col min-w-full font-sans max-w-md p-8 shadow-grayOutline rounded-xl lg:p-12 ">
//       <div className="flex flex-col items-center w-full">
//         <h2 className="text-2xl font-semibold text-center text-gray-900">
//           Your opinion matters!
//         </h2>
//         <div className="flex flex-col items-center py-4 space-y-3">
//           <span className="text-center text-gray-800">
//             How was your experience?
//           </span>
//         </div>
        
//         <div className="flex flex-col w-full">
//           <textarea
//             rows="3"
//             placeholder="Message..."
//             className="p-4 rounded-md resize-none border-2 border-teal-100"
//             name='comment'
//             onChange={handleChange}
//             value={formData.comment}
//           ></textarea>
//           <button
//             type="button"
//             className="py-3 mt-4 rounded-md inline-flex items-center justify-center text-base sm:text-lg font-medium leading-6 tracking-wide text-white transition duration-150 ease-in-out bg-teal-500 border border-transparent hover:bg-teal-700 focus:outline-none;"
//             onClick={handleSubmit}
//           >
//             Leave feedback
//           </button>
//         </div>
//       </div>
      
//     </div>
//   );
// };

// export default ReviewTaker;

import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FaStar } from 'react-icons/fa'; // Import the star icon
import { createReviewAction } from '../../Redux/Review/actions';

const ReviewTaker = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const productId = searchParams.get('productId');
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [rating, setRating] = useState(0); // Initialize rating with 0

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleStarClick = (index) => {
    setRating(index + 1); // Update rating based on clicked star
  };

  const handleSubmit = (e) => {
    // Add rating and productId to formData
    const reviewData = { ...formData, rating, productId };
    dispatch(createReviewAction(reviewData));
    // Clear form data
    setFormData({});
    setRating(0);
  };

  return (
    <div className="flex flex-col min-w-full font-sans max-w-md p-8 shadow-grayOutline rounded-xl lg:p-12 ">
      <div className="flex flex-col items-center w-full">
        <h2 className="text-2xl font-semibold text-center text-gray-900">
          Your opinion matters!
        </h2>
        <div className="flex flex-col items-center py-4 space-y-3">
          <span className="text-center text-gray-800">
            How was your experience?
          </span>
          <div className="flex space-x-1">
            {[...Array(5)].map((_, index) => (
              <FaStar
                key={index}
                size={24}
                onClick={() => handleStarClick(index)}
                color={index < rating ? "gold" : "gray"} // Highlight stars based on rating
                style={{ cursor: "pointer" }}
              />
            ))}
          </div>
        </div>
        
        <div className="flex flex-col w-full">
          <textarea
            rows="3"
            placeholder="Message..."
            className="p-4 rounded-md resize-none border-2 border-teal-100"
            name="comment"
            onChange={handleChange}
            value={formData.comment || ""}
          ></textarea>
          <button
            type="button"
            className="py-3 mt-4 rounded-md inline-flex items-center justify-center text-base sm:text-lg font-medium leading-6 tracking-wide text-white transition duration-150 ease-in-out bg-teal-500 border border-transparent hover:bg-teal-700 focus:outline-none;"
            onClick={handleSubmit}
          >
            Leave feedback
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReviewTaker;
