import { createUserApi, loginUserApi, getAllUsers } from "../../Api";
import { showNotification } from "../notification/actions";

export const CREATE_ACCOUNT = "CREATE_ACCOUNT";
export const Add_LOGIN_USER = "Add_LOGIN_USER";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const LOGOUT = "LOGOUT";

export const createAccount = (formData, navigate) => async (dispatch) => {
  try {
    const { data } = await createUserApi(formData);
    dispatch({ type: Add_LOGIN_USER, payload: data });
    dispatch(
      showNotification({
        massageType: "success",
        message: "Account created successfully",
      })
    );
    navigate("/");
    // history.push("/");
  } catch (error) {
    console.log(error?.message);
    dispatch(
      showNotification({
        message: error?.response?.data?.message,
        massageType: "error",
      })
    );
    console.log(error);
  }
};

export const loginAccount = (formData, navigate) => async (dispatch) => {
  try {
    const { data } = await loginUserApi(formData);
    console.log("🚀 ~ file: actions.js ~ line 34 ~ data", data);
    dispatch({ type: Add_LOGIN_USER, payload: data });
    dispatch(
      showNotification({
        massageType: "success",
        message: `welcome ${data.userInfo.name}`,
      })
    );
    navigate("/");
    // history.push("/");
  } catch (error) {
    dispatch(
      showNotification({
        message: error?.response?.data?.message,
        massageType: "error",
      })
    );
    console.log(error);
  }
};

export const logOut = () => async (dispatch) => {
  localStorage.setItem("profile", "null");
  dispatch(
    showNotification({
      message: "Log Out Successful",
      massageType: "success",
    })
  );
  // dispatch({
  //   type: LOGOUT,
  //   payload: {
  //     token: null,
  //   },
  // });
};

export const getUsers = () => async (dispatch) => {
  try {
    const { data } = await getAllUsers();
    dispatch({ type: GET_ALL_USERS, payload: data });
  } catch (error) {
    dispatch(
      showNotification({
        message: "Failed to get users",
        massageType: "error",
      })
    );
  }
};