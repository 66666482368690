import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux'
import {getCartItems, updateCart, deleteProductFromCart} from "../../../Redux/Cart/actions";



const OrderCart = ({ open, setOpen }) => {
  const updateQuantity = (productId, quantity) => {
    if(quantity>=1)
    dispatch(updateCart(productId, quantity));
  }
   const deleteItem = (productId) => {
    dispatch(deleteProductFromCart(productId));
  }
  const dispatch = useDispatch()
  const cartState = useSelector(state => state.cart)
  const {cart, loading, error} = cartState;
  useEffect(() => {
    dispatch(getCartItems());
  }, [dispatch])

    // Calculate the subtotal
    const subtotal = cart?.reduce((total, item) => {
      return total + (item.productId.price * item.quantity);
    }, 0);
  return (
    <div>
     
        <Dialog
          as="div"
          className="fixed inset-0 overflow-hidden z-50"
          open={open}
          onClose={setOpen}
        >
          <div className="absolute inset-0 overflow-hidden">
           

            <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
             
                <div className="w-screen max-w-md font-sans">
                  <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                    <div className="flex-1 py-6 overflow-y-auto px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                       
                        <div className="ml-3 h-7 flex items-center">
                          <button
                            type="button"
                            className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                           
                          </button>
                        </div>
                      </div>

                      <div className="mt-8">
                        <div className="flow-root">
                          <ul
                            role="list"
                            className="-my-6 divide-y divide-gray-200"
                          >
                            {cart?.map((item) => (
                              <li key={item._id} className="py-6 flex " style={{display:'flex', flexDirection:'column'}} >
                                <Link to={`/productDetails?productId=${item.productId._id}`}>
                                <div className="flex-shrink-0 w-24 h-24 border border-gray-200 rounded-md overflow-hidden">
                                  <img
                                    src={`data:image/jpeg;base64,${item.productId.image}`}
                                    alt="product"
                                    className="w-full h-full object-center object-cover"
                                  />
                                </div>
                                </Link>
                                <div className="ml flex-1 flex flex-col">
                                  <div>
                                    <div className="flex justify-between text-base font-medium text-gray-800">
                                    <Link to={`/productDetails?productId=${item.productId._id}`}>
                                      <h3>
                                        {/* <a href={product.href}> */}
                                          {item.productId.name}
                                        {/* </a> */}
                                      </h3>
                                      </Link>
                                      <p className="ml-4">&#8377; {item.productId.price}</p>
                                    </div>
                                    <p className="mt-1 text-sm text-gray-500">
                                      {/* {product.color} */}
                                    </p>
                                  </div>
                                  <div className="flex-1 flex items-end justify-between text-sm">
                                    <div className="border border-gray-300 rounded">
                                      <i className="fas fa-plus m-1 py-1 px-4 cursor-pointer font-normal text-teal-600" onClick={()=>updateQuantity(item.productId._id, item.quantity+1)}></i>
                                      <span className="mx-2 text-center w-1 text-gray-900">
                                        {item.quantity}
                                      </span>

                                      <i className="fas fa-minus m-1 py-1 px-4 cursor-pointer font-normal text-teal-600" onClick={()=>updateQuantity(item.productId._id, item.quantity-1)}></i>
                                    </div>

                                    <div className="flex">
                                      <button
                                        type="button"
                                        className="font-medium tracking-wide text-teal-600 hover:text-teal-800"
                                        onClick={()=>deleteItem(item.productId._id)}
                                      >
                                        Remove
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="border-t border-gray-200 py-6 px-4 sm:px-6">
                      <div className="flex justify-between text-base font-medium text-gray-800">
                        <p>Subtotal</p>
                        <p>&#8377; {subtotal}</p>
                      </div>
                      <p className="mt-0.5 text-sm text-gray-500">
                        Shipping and taxes calculated at checkout.
                      </p>
                      <div className="mt-6">
                        <Link
                          onClick={() => setOpen(false)}
                          to="/checkout"
                          className="flex justify-center items-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-teal-500 hover:bg-teal-700"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 mr-1"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                            />
                          </svg>
                          Checkout
                        </Link>
                      </div>
                      <div className="mt-6 flex justify-center text-sm text-center text-gray-500">
                        <p>
                          or{' '}
                          <button
                            type="button"
                            className="text-teal-500 font-medium hover:text-teal-700"
                            onClick={() => setOpen(false)}
                          >
                            Continue Shopping
                            <span aria-hidden="true"> &rarr;</span>
                          </button>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </Dialog>
    </div>
  );
};

export default OrderCart;