import React, { useEffect } from 'react';
import VendorLayout from '../../../Components/Admin/Vendor/VendorLayout';
import { useNavigate } from 'react-router-dom';

const VenderDashboardPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    const profile = localStorage.getItem('profile');
    if (profile) {
      const user = JSON.parse(profile);

      // Check if the user is not an admin
      if (!user || !user.userInfo.isAdmin ) {
        // Redirect to the home page if not an admin
        navigate('/');
      }
    } else {
      // Redirect to the home page if profile doesn't exist
      navigate('/');
    }
  }, [navigate]);

  return (
    <>
      <VendorLayout />
    </>
  );
};

export default VenderDashboardPage;
