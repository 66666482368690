import { 
    CREATE_MESSAGE_REQUEST,
    CREATE_MESSAGE_SUCCESS,
    CREATE_MESSAGE_FAILURE, 
    GET_MESSAGE_REQUEST,
    GET_MESSAGE_SUCCESS,
    GET_MESSAGE_FAILURE,
    
     } from "./actions";

const initialState = {
    messages: [],
    loading: false,
    error: null
};

export default function messageReducer(state = initialState, action) {
    switch (action.type) {
        case CREATE_MESSAGE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case CREATE_MESSAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                messages: [...state.messages, action.payload]
            };
        case CREATE_MESSAGE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
            
        case GET_MESSAGE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_MESSAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                messages: action.payload
            };
        case GET_MESSAGE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
            
        default:
            return state;
    }
}