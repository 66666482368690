import React from 'react';
import {useSelector, useDispatch} from 'react-redux'
import {updateItemStatusAction, updateOrderStatusAction} from '../../Redux/order/actions'
const EditOrders = ({ setEditModal,order }) => {
 
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  const dispatch = useDispatch();
  const updateOrderStatus = (e, orderId) => {
    dispatch(updateOrderStatusAction(orderId, e.target.value));
  }
  const updateItemStatus = (e, orderId, itemId) => {
    dispatch(updateItemStatusAction(orderId, itemId, e.target.value));
  }
  return (
    <section>
      <div className="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none backdrop-filter saturate-150 backdrop-blur-sm">
        <div className="container items-center px-5 py-12 lg:px-20 ml-0 lg:ml-20">
          <div
            className="w-full mx-auto border rounded-lg shadow-xl text-gray-800 bg-white"
            aria-hidden="false"
            aria-describedby="modalDescription"
            role="dialog"
          >
            <div className="bg-white shadow overflow-hidden sm:rounded-lg font-body font-medium">
              <div className="flex items-center justify-between pt-8 px-5 mb-4">
                <div className="max-w-xl ml-3">
                  <h1 className="text-2xl font-semibold tracking-tight text-teal-500 sm:text-3xl ">
                    Order Details
                  </h1>
                  <p className="mt-2 text-sm text-gray-500">
                    Check the status of recent orders, manage returns, and
                    download invoices.
                  </p>
                </div>

                <button
                  className="justify-end p-1 mr-4 -mt-6 transition-colors duration-200 transform rounded-md hover:bg-opacity-25 text-teal-600 hover:bg-teal-700 focus:outline-none"
                  type="button"
                  aria-label="Close"
                  aria-hidden="true"
                  onClick={() => setEditModal(false)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-circle-x"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <circle cx="12" cy="12" r="9"></circle>
                    <path d="M10 10l4 4m0 -4l-4 4"></path>
                  </svg>
                </button>
              </div>

              {/* Order Details */}
              <div className="bg-white">
                <div className="max-w-7xl mx-auto  px-4 sm:px-6 pb-16 lg:px-8">
                  <div className="mt-1">
                    <h2 className="sr-only">Recent orders</h2>

                    <div className="space-y-20">
                      
                        <div key={order.number}>
                          

                          <div className="bg-gray-50 rounded-lg py-6 px-4 sm:px-6 sm:flex sm:items-center sm:justify-between sm:space-x-6 lg:space-x-8">
                            <dl className="divide-y font-sans divide-gray-200 space-y-6 text-sm text-gray-600 flex-auto sm:divide-y-0 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-x-6 lg:w-2/3 lg:flex-none lg:gap-x-8">
                             
                              <div className="flex justify-between sm:block">
                                <dt className="font-medium text-gray-900">
                                  Customer Name
                                </dt>
                                <dd className="sm:mt-1">
                                  <time dateTime={order.createdAt}>
                                    {order.shippingInfo.customerName}
                                  </time>
                                </dd>
                              </div>
                              
                              <div className="flex justify-between pt-6 sm:block sm:pt-0">
                                <dt className="font-medium text-gray-900">
                                  Phone Number
                                </dt>
                                <dd className="sm:mt-1">{order.shippingInfo.phoneNumber}</dd>
                              </div>

                              <div className="flex justify-between sm:block">
                                <dt className="font-medium text-gray-900">
                                  Date placed
                                </dt>
                                <dd className="sm:mt-1">
                                {new Date(order.createdAt).toLocaleDateString('en-US', options)}
                                </dd>
                              </div>

                              <div className="flex justify-between pt-6 font-medium text-gray-900 sm:block sm:pt-0">
                                <dt>Total amount</dt>
                                <dd className="sm:mt-1">&#8377; {order.totalPrice}</dd>
                              </div>
                            </dl>

                           
                          </div>

                          <div className="bg-gray-50 rounded-lg py-6 px-4 sm:px-6 sm:flex sm:items-center sm:justify-between sm:space-x-6 lg:space-x-8">
                            <dl className="divide-y font-sans divide-gray-200 space-y-6 text-sm text-gray-600 flex-auto sm:divide-y-0 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-x-6 lg:w-2/3 lg:flex-none lg:gap-x-8">
                              <div className="flex justify-between sm:block">
                                <dt className="font-medium text-gray-900">
                                  Contry
                                </dt>
                                <dd className="sm:mt-1">
                                  {order.shippingInfo.country}
                                </dd>
                              </div>
                              <div className="flex justify-between pt-6 sm:block sm:pt-0">
                                <dt className="font-medium text-gray-900">
                                  State
                                </dt>
                                <dd className="sm:mt-1">{order.shippingInfo.state}</dd>
                              </div>
                              <div className="flex justify-between pt-6 font-medium text-gray-900 sm:block sm:pt-0">
                                <dt>City</dt>
                                <dd className="sm:mt-1">{order.shippingInfo.city}</dd>
                              </div>
                              <div className="flex justify-between pt-6 sm:block sm:pt-0">
                                <dt className="font-medium text-gray-900">
                                 Address Line-1
                                </dt>
                                <dd className="sm:mt-1">{order.shippingInfo.addressLine1}</dd>
                              </div>
                            </dl>

                            <div className="flex justify-between pt-6 sm:block sm:pt-0">
                                <dt className="font-medium text-gray-900">
                                 Status
                                </dt>
                                <dd className="sm:mt-1">{order.status}</dd>
                              </div>
                              
                             
                          </div>

                          <div className="bg-gray-50 rounded-lg py-6 px-4 sm:px-6 sm:flex sm:items-center sm:justify-between sm:space-x-6 lg:space-x-8">
                            <dl className="divide-y font-sans divide-gray-200 space-y-6 text-sm text-gray-600 flex-auto sm:divide-y-0 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-x-6 lg:w-2/3 lg:flex-none lg:gap-x-8">
                              <div className="flex justify-between sm:block">
                                <dt className="font-medium text-gray-900">
                                  Address Line-2
                                </dt>
                                <dd className="sm:mt-1">
                                  {order.shippingInfo.addressLine2?order.shippingInfo.addressLine2:'-'}
                                </dd>
                              </div>
                              <div className="flex justify-between pt-6 sm:block sm:pt-0">
                                <dt className="font-medium text-gray-900">
                                  Postal Code
                                </dt>
                                <dd className="sm:mt-1">{order.shippingInfo.postalCode}</dd>
                              </div>
                              <div className="flex justify-between pt-6 font-medium text-gray-900 sm:block sm:pt-0">
                                <dt>Street</dt>
                                <dd className="sm:mt-1">{order.shippingInfo.street}</dd>
                              </div>
                              <div className="flex justify-between pt-6 sm:block sm:pt-0">
                                <dt className="font-medium text-gray-900">
                                 House Number
                                </dt>
                                <dd className="sm:mt-1">{order.shippingInfo.houseNumber}</dd>
                              </div>
                              
                            </dl>
                            <p className="block w-52 font-semibold py-2 px-3 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500">
                              Payment: <span className='text-gray-900'>{order.paymentType}</span>
                            </p>
                            
                          </div>

                          <table className="mt-4 w-full text-gray-500 sm:mt-6">
                            <caption className="sr-only">Products</caption>
                            <thead className="sr-only text-sm text-gray-900 text-left sm:not-sr-only">
                              <tr>
                                <th
                                  scope="col"
                                  className="sm:w-2/5 lg:w-1/3 pr-8 py-3 font-medium"
                                >
                                  Product
                                </th>
                                <th
                                  scope="col"
                                  className="hidden w-1/5 pr-8 py-3 font-medium sm:table-cell"
                                >
                                  Price
                                </th>
                                <th
                                  scope="col"
                                  className="hidden pr-8 py-3 font-medium sm:table-cell"
                                >
                                  Quantity
                                </th>
                                <th
                                  scope="col"
                                  className="hidden pr-8 py-3 font-medium sm:table-cell"
                                >
                                  Category
                                </th>
                                <th
                                  scope="col"
                                  className="w-0 py-3 font-medium text-right"
                                >
                                  Status
                                </th>
                              </tr>
                            </thead>
                            <tbody className="border-b border-gray-200 divide-y text-gray-800 divide-gray-200 text-sm sm:border-t">
                              {order.items.map((product) => (
                                <tr key={product._id}>
                                  <td className="py-6 pr-8">
                                    <div className="flex items-center">
                                      <img
                                        src={`data:image/jpeg;base64,${product.productId.image}`}
                                        alt="image"
                                        className="w-16 h-16 object-center object-cover rounded mr-6"
                                      />
                                      <div>
                                        <div className="font-medium text-gray-800">
                                          {product.productId.name}
                                        </div>
                                        <div className="mt-1 sm:hidden">
                                        &#8377; {product.price}
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td className="hidden py-6 pr-8 sm:table-cell">
                                  &#8377; {product.price}
                                  </td>
                                  <td className="hidden py-6 pr-8 sm:table-cell">
                                    {product.quantity}
                                  </td>
                                  <td className="hidden py-6 pr-8 sm:table-cell">
                                    {product.productId.category}
                                  </td>
                                  <td className="py-6 font-medium text-right whitespace-nowrap cursor-pointer">
                                  {product.status}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Background Modal Opacity */}
      <div className="opacity-25 fixed inset-0 z-40 bg-gray-900" />
    </section>
  );
};

export default EditOrders;
