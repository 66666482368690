import {
    getAllOrder,
    createOrder,
    updateOrderStatus,
    updateItemStatus,
    getOrderById,
    getOrderByUserId
} from "../../Api/index";
import { showNotification } from "../notification/actions";
import Swal from 'sweetalert2'; 

// Action Types
export const FETCH_ORDERS_REQUEST = "FETCH_ORDERS_REQUEST";
export const FETCH_ORDERS_SUCCESS = "FETCH_ORDERS_SUCCESS";
export const FETCH_ORDERS_FAILURE = "FETCH_ORDERS_FAILURE";

export const CREATE_ORDER_REQUEST = "CREATE_ORDER_REQUEST";
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS";
export const CREATE_ORDER_FAILURE = "CREATE_ORDER_FAILURE";

export const UPDATE_ORDER_STATUS_REQUEST = "UPDATE_ORDER_STATUS_REQUEST";
export const UPDATE_ORDER_STATUS_SUCCESS = "UPDATE_ORDER_STATUS_SUCCESS";
export const UPDATE_ORDER_STATUS_FAILURE = "UPDATE_ORDER_STATUS_FAILURE";

export const UPDATE_ITEM_STATUS_REQUEST = "UPDATE_ITEM_STATUS_REQUEST";
export const UPDATE_ITEM_STATUS_SUCCESS = "UPDATE_ITEM_STATUS_SUCCESS";
export const UPDATE_ITEM_STATUS_FAILURE = "UPDATE_ITEM_STATUS_FAILURE";

export const FETCH_ORDER_BY_ID_REQUEST = "FETCH_ORDER_BY_ID_REQUEST";
export const FETCH_ORDER_BY_ID_SUCCESS = "FETCH_ORDER_BY_ID_SUCCESS";
export const FETCH_ORDER_BY_ID_FAILURE = "FETCH_ORDER_BY_ID_FAILURE";

export const FETCH_ORDERS_BY_USER_REQUEST = "FETCH_ORDERS_BY_USER_REQUEST";
export const FETCH_ORDERS_BY_USER_SUCCESS = "FETCH_ORDERS_BY_USER_SUCCESS";
export const FETCH_ORDERS_BY_USER_FAILURE = "FETCH_ORDERS_BY_USER_FAILURE";

// Actions

// Fetch all orders
export const fetchOrders = () => async (dispatch) => {
    dispatch({ type: FETCH_ORDERS_REQUEST });

    try {
        const response = await getAllOrder();
        dispatch({ type: FETCH_ORDERS_SUCCESS, payload: response.data.orders });
    } catch (error) {
        dispatch({ type: FETCH_ORDERS_FAILURE, payload: error.message });
        dispatch(showNotification({ messageType: "error", message: "Failed to fetch orders." }));
    }
};


// Create a new order
export const createOrderAction = (cart, shippingInfo, paymentType) => async (dispatch) => {
    dispatch({ type: CREATE_ORDER_REQUEST });

    try {
        // Extract the required fields from each cart item
        const items = cart.map(item => ({
            productId: item.productId._id, // Extract productId
            quantity: item.quantity,       // Extract quantity
            price: item.productId.price    // Extract price
        }));

        const formData = {
            items,
            shippingInfo,
            paymentType
        };

        const response = await createOrder(formData);
        
        window.location.href = response.data.paymentLinkUrl;
        dispatch({ type: CREATE_ORDER_SUCCESS, payload: response.data });

        // Show success notification using SweetAlert2
        Swal.fire({
            title: 'Success!',
            text: 'Order created successfully.',
            icon: 'success',
            confirmButtonText: 'OK'
        });
    } catch (error) {
        dispatch({ type: CREATE_ORDER_FAILURE, payload: error.message });

        // Show error notification using SweetAlert2
        Swal.fire({
            title: 'Error!',
            text: 'Failed to create order.',
            icon: 'error',
            confirmButtonText: 'Try Again'
        });
    }
};
// Update order status
export const updateOrderStatusAction = (orderId, status) => async (dispatch) => {
    dispatch({ type: UPDATE_ORDER_STATUS_REQUEST });

    try {
        const response = await updateOrderStatus(orderId, status);
        dispatch({ type: UPDATE_ORDER_STATUS_SUCCESS, payload: response.data });
 // Show success notification using SweetAlert2
 Swal.fire({
    title: 'Success!',
    text: 'Order Status Changed successfully.',
    icon: 'success',
    confirmButtonText: 'OK'
});    } catch (error) {
        dispatch({ type: UPDATE_ORDER_STATUS_FAILURE, payload: error.message });
 // Show error notification using SweetAlert2
 Swal.fire({
    title: 'Error!',
    text: 'Failed to change order status.',
    icon: 'error',
    confirmButtonText: 'Try Again'
});    }
};

// Update item status in an order
export const updateItemStatusAction = (orderId, itemId, status) => async (dispatch) => {
    dispatch({ type: UPDATE_ITEM_STATUS_REQUEST });

    try {
        const response = await updateItemStatus(orderId, itemId, status);
        dispatch({ type: UPDATE_ITEM_STATUS_SUCCESS, payload: response.data });
 // Show error notification using SweetAlert2
 Swal.fire({
    title: 'Success!',
    text: 'Item Status Changed successfully.',
    icon: 'success',
    confirmButtonText: 'OK'
});    } catch (error) {
        dispatch({ type: UPDATE_ITEM_STATUS_FAILURE, payload: error.message });
 // Show error notification using SweetAlert2
 Swal.fire({
    title: 'Error!',
    text: 'Failed to change item status.',
    icon: 'error',
    confirmButtonText: 'Try Again'
});     }
};

// Fetch an order by ID
export const fetchOrderById = (orderId) => async (dispatch) => {
    dispatch({ type: FETCH_ORDER_BY_ID_REQUEST });

    try {
        const response = await getOrderById(orderId);
        dispatch({ type: FETCH_ORDER_BY_ID_SUCCESS, payload: response.data });
    } catch (error) {
        dispatch({ type: FETCH_ORDER_BY_ID_FAILURE, payload: error.message });
        dispatch(showNotification({ messageType: "error", message: "Failed to fetch order by ID." }));
    }
};

// Fetch orders by user ID
export const fetchOrdersByUser = (userId) => async (dispatch) => {
    dispatch({ type: FETCH_ORDERS_BY_USER_REQUEST });

    try {
        const response = await getOrderByUserId(userId);
        dispatch({ type: FETCH_ORDERS_BY_USER_SUCCESS, payload: response.data.orders });
    } catch (error) {
        dispatch({ type: FETCH_ORDERS_BY_USER_FAILURE, payload: error.message });
        dispatch(showNotification({ messageType: "error", message: "Failed to fetch orders by user." }));
    }
};
