// import ProgressBar from '@ramonak/react-progress-bar';
// import React from 'react';
// import ShowRating from '../../Common/ShowRating';
// import {getOverviewAction} from '../../../Redux/Review/actions';
// import {useDispatch, useSelector} from 'react-redux'
// import { useLocation } from 'react-router-dom';

// const AllStar = () => {
//   const location = useLocation();
//   const searchParams = new URLSearchParams(location.search);
//   const productId = searchParams.get('productId');
//   const dispatch = useDispatch();
//   const reviewState = useSelector((state) => state.reviews);
//   const {loading, error, overview} = reviewState;
//   useEffect(() => {
//     dispatch(getOverviewAction());
//   }, [dispatch, productId]);
//   return (
//     <div className="flex flex-col min-w-full max-w-md p-8 shadow-grayOutline mb-5 rounded-xl lg:p-12">
//       <div className="flex flex-col w-full font-sans">
//         <h2 className="text-2xl mb-4 font-sans font-semibold text-center text-gray-900">
//           Reviews Overview
//         </h2>
//         <div className="flex flex-wrap items-center mt-2 mb-1 space-x-2">
//           <div className="flex outline-none border-0">
//             <ShowRating value={4} />
//           </div>
//           <span className="text-gray-800">3 out of 5</span>
//         </div>
//         <p className="text-sm text-gray-800">861 total ratings</p>
//         <div className="flex flex-col mt-4">
//           <div className="flex items-center space-x-1">
//             <span className="flex-shrink-0 w-12 text-sm text-gray-800">
//               5 star
//             </span>
//             <div className="w-full">
//               <ProgressBar
//                 completed={60}
//                 margin={5}
//                 bgColor="#00BFA5"
//                 labelSize={12}
//               />
//             </div>
//           </div>
//           <div className="flex items-center space-x-1">
//             <span className="flex-shrink-0 w-12 text-sm text-gray-800">
//               4 star
//             </span>
//             <div className="w-full">
//               <ProgressBar
//                 completed={60}
//                 margin={5}
//                 bgColor="#00BFA5"
//                 labelSize={12}
//               />
//             </div>
//           </div>
//           <div className="flex items-center space-x-1">
//             <span className="flex-shrink-0 w-12 text-sm text-gray-800">
//               3 star
//             </span>
//             <div className="w-full">
//               <ProgressBar
//                 completed={60}
//                 margin={5}
//                 bgColor="#00BFA5"
//                 labelSize={12}
//               />
//             </div>
//           </div>
//           <div className="flex items-center space-x-1">
//             <span className="flex-shrink-0 w-12 text-sm text-gray-800">
//               2 star
//             </span>
//             <div className="w-full">
//               <ProgressBar
//                 completed={60}
//                 margin={5}
//                 bgColor="#00BFA5"
//                 labelSize={12}
//               />
//             </div>
//           </div>
//           <div className="flex items-center space-x-1">
//             <span className="flex-shrink-0 w-12 text-sm text-gray-800">
//               1 star
//             </span>
//             <div className="w-full">
//               <ProgressBar
//                 completed={21}
//                 margin={5}
//                 bgColor="#00BFA5"
//                 labelSize={12}
//               />
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AllStar;
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { FaStar } from 'react-icons/fa';
import ProgressBar from '@ramonak/react-progress-bar';
import { getOverviewAction } from '../../../Redux/Review/actions';

const AllStar = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const productId = searchParams.get('productId');
  const dispatch = useDispatch();

  const reviewState = useSelector((state) => state.reviews);
  const { loading, error, overview } = reviewState;

  useEffect(() => {
    if (productId) {
      dispatch(getOverviewAction(productId));
    }
  }, [dispatch, productId]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  // Round the average rating value
  const roundedRating = Math.round(overview?.averageRating || 0);

  // Function to render stars
  const renderStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <FaStar
          key={i}
          size={24}
          color={i <= rating ? '#FFD700' : '#D3D3D3'}
        />
      );
    }
    return stars;
  };

  return (
    <div className="flex flex-col min-w-full max-w-md p-8 shadow-grayOutline mb-5 rounded-xl lg:p-12">
      <div className="flex flex-col w-full font-sans">
        <h2 className="text-2xl mb-4 font-sans font-semibold text-center text-gray-900">
          Reviews Overview
        </h2>
        <div className="flex flex-wrap items-center mt-2 mb-1 space-x-2 " style={{display:'flex', justifyContent:'center'}}>
          <div className="flex outline-none border-0">
            {renderStars(roundedRating)}
          </div>
         
        </div>
        <p className="text-sm text-gray-800">{overview?.totalRatings} total ratings</p>
        <div className="flex flex-col mt-4">
          {Object.entries(overview?.ratingPercentages || {}).map(([star, percentage]) => (
            <div key={star} className="flex items-center space-x-1">
              <span className="flex-shrink-0 w-12 text-sm text-gray-800">
                {star} star
              </span>
              <div className="w-full">
                <ProgressBar
                  completed={Math.round(percentage)}
                  margin={5}
                  bgColor="#00BFA5"
                  labelSize={12}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AllStar;


