import React, { useEffect } from 'react';
import Footer from '../../Components/Home/Footer/Footer';
import Header from '../../Components/Home/NavBar/Header';
import NavBar from '../../Components/Home/NavBar/NavBar';
import Menu from '../../Components/ProductDetails/Menu';
import ProductInfo from '../../Components/ProductDetails/ProductInfo';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {fetchProductById} from '../../Redux/product/action'
const ProductDetails = () => {
 
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const productId = searchParams.get('productId');
  const productState = useSelector((state) => state.products);
  const { loading, product, error } = productState;
  const dispatch = useDispatch();

  useEffect(() => {
    if (productId) {
      dispatch(fetchProductById(productId));
    }
  }, [productId, dispatch]); 
  return (
    <>
      <Header />
      <NavBar />
      <section className="overflow-hidden">
        <div className="px-4 py-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
          <ProductInfo product={product} />
          <Menu product={product} />
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ProductDetails;
