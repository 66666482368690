import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Categories from '../../Components/Categories/Categories';
import Header from '../../Components/Home/NavBar/Header';
import NavBar from '../../Components/Home/NavBar/NavBar';
import Footer from '../../Components/Home/Footer/Footer';
import {addProductToCart} from '../../Redux/Cart/actions'
import { useDispatch } from 'react-redux';
import { useState } from 'react';
const AllProducts = () => {
  const dispatch = useDispatch();
  const handleAddToCart = (productId) => {
    dispatch(addProductToCart(productId, 1));
  }
  const productsState = useSelector((state) => state.products);
  const { loading, products, error } = productsState;
  const [search, setSearch] = useState('');
  const filteredProducts = products.filter((product) =>
    product.name.toLowerCase().includes(search.toLowerCase())
  );
  const truncateText = (text, wordLimit) => {
    if (!text) return ''; // Handle cases where description might be null or undefined
    const words = text.split(' '); // Split the description into words
    if (words.length <= wordLimit) return text; // Return the full description if within limit
    return words.slice(0, wordLimit).join(' ') + '...'; // Join the words up to the limit and add ellipsis
  };
  return (
    <div className="bg-white">
      <Header />
      <NavBar />
      <div className="max-w-2xl mx-auto pb-10 px-4 sm:px-6 lg:max-w-7xl lg:px-8 pt-10 sm:pt-10">
      
       <Categories search={search} setSearch={setSearch}/>
        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p>Error: {error}</p>
        ) : (
          <div className="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
            {filteredProducts.map((product) => (
              <div key={product.id} className="group shadow-4xl">
                
                  <div className="w-full min-h-80 bg-blue-200 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:h-80 lg:aspect-none">
                    <img
                      src={product.image}
                      alt="Image"
                      className="w-full h-full object-center object-cover lg:w-full lg:h-full"
                    />
                  </div>

                  <div className="mt-4 flex justify-between px-2">
                    <div>
                      <h3 className="text-md text-gray-900 font-display tracking-wide">
                        <a href={product.href}>
                          <span aria-hidden="true" className="inset-0" />
                          {product.name}
                        </a>
                      </h3>
                    </div>
                    <p className="text-md font-medium text-teal-600 flex">
                    &#8377;{product.price}
                    </p>
                  </div>

                  <div>
                    <p className="text-sm mt-1 px-2 text-gray-900 font-sans tracking-wide">
                      {product.category}
                    </p>
                  </div>
                  <div>
                    <p className="text-sm mt-1 px-2 text-gray-900 font-sans tracking-wide">
                    {truncateText(product.description, 10)} 
                    </p>
                  </div>

                  <div className="flex justify-between my-4 px-2 items-center">
                  <Link to={`/productDetails?productId=${product._id}`}>
                    <div>
                     
                      <button
                        className="hover:bg-teal-400 text-gray-900 hover:text-teal-50 p-2 rounded-full transition duration-150 ease-in-out"
                        title="Quick View"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                          />
                        </svg>
                      </button>
                    </div>
                    </Link>
                      <button className="flex py-2 px-3 text-sm rounded shadow-lg bg-teal-500 focus:outline-none active:bg-teal-500 text-white transition duration-150 ease-in-out hover:bg-teal-700" 
                      onClick={()=> handleAddToCart(product._id)}>
                        Add to Cart
                      </button>
                    
                  </div>
               
              </div>
            ))}
          </div>
        )}
        </div>
       <Footer/>
        </div>
  )
}
     
export default AllProducts;