import { getAllProduct, getAllProductByCategory, createProduct, updateProduct, deleteProduct, getProductById } from "../../Api/index";
import { showNotification } from "../notification/actions";

export const FETCH_PRODUCT_REQUEST = "FETCH_PRODUCT_REQUEST";
export const FETCH_PRODUCT_SUCCESS = "FETCH_PRODUCT_SUCCESS";
export const FETCH_PRODUCT_FAILURE = "FETCH_PRODUCT_FAILURE";

export const CREATE_PRODUCT_REQUEST = "CREATE_PRODUCT_REQUEST";
export const CREATE_PRODUCT_SUCCESS = "CREATE_PRODUCT_SUCCESS";
export const CREATE_PRODUCT_FAILURE = "CREATE_PRODUCT_FAILURE";

export const UPDATE_PRODUCT_REQUEST = "UPDATE_PRODUCT_REQUEST";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAILURE = "UPDATE_PRODUCT_FAILURE";

export const DELETE_PRODUCT_REQUEST = "DELETE_PRODUCT_REQUEST";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAILURE = "DELETE_PRODUCT_FAILURE";

export const FETCH_PRODUCTBYID_REQUEST = "FETCH_PRODUCTBYID_REQUEST";
export const FETCH_PRODUCTBYID_SUCCESS = "FETCH_PRODUCTBYID_SUCCESS";
export const FETCH_PRODUCTBYID_FAILURE = "FETCH_PRODUCTBYID_FAILURE";
export const FETCH_ALL_PRODUCTS_SUCCESS = "FETCH_ALL_PRODUCTS_SUCCESS";
export const fetchProducts = (category = "All") => async (dispatch) => {
  dispatch({ type: FETCH_PRODUCT_REQUEST });

  try {
      

      if (category === "All") {
          const response = await getAllProduct();
         const data = response.data;
          dispatch({ type: FETCH_ALL_PRODUCTS_SUCCESS, payload: data });
      } 
      else if(category == "All Products"){
         const response = await getAllProduct();
         const data = response.data;
          dispatch({ type: FETCH_PRODUCT_SUCCESS, payload: data });
      }
      else {
          const response = await getAllProductByCategory(category);
         const data = response.data;
          dispatch({ type: FETCH_PRODUCT_SUCCESS, payload: data });
      }

  } catch (error) {
      dispatch({ type: FETCH_PRODUCT_FAILURE, payload: error.message });
      dispatch(showNotification({ massageType: "error", message: "Failed to fetch products." }));
  }
};

export const fetchProductById = (id) => async (dispatch) => {
  dispatch({ type: FETCH_PRODUCTBYID_REQUEST });

  try {
          const response = await getProductById(id);
          const data = response.data;
          console.log(data)
      dispatch({ type: FETCH_PRODUCTBYID_SUCCESS, payload: data });
  } catch (error) {
      dispatch({ type: FETCH_PRODUCTBYID_FAILURE, payload: error.message });
      dispatch(showNotification({ massageType: "error", message: "Failed to fetch product." }));
  }
};

export const createProductAction = (formData) => async (dispatch) => {
    dispatch({ type: CREATE_PRODUCT_REQUEST });

    try {
        const { data } = await createProduct(formData);
        dispatch({ type: CREATE_PRODUCT_SUCCESS, payload: data });
        dispatch(showNotification({ massageType: "success", massage: "Product created successfully." }));
    } catch (error) {
        dispatch({ type: CREATE_PRODUCT_FAILURE, payload: error.message });
        dispatch(showNotification({ massageType: "error", massage: "Failed to create product." }));
    }
};

export const updateProductAction = (id, formData) => async (dispatch) => {
    dispatch({ type: UPDATE_PRODUCT_REQUEST });

    try {
        const { data } = await updateProduct(id, formData);
        dispatch({ type: UPDATE_PRODUCT_SUCCESS, payload: data });
        dispatch(showNotification({ massageType: "success", massage: "Product updated successfully." }));
    } catch (error) {
        dispatch({ type: UPDATE_PRODUCT_FAILURE, payload: error.message });
        dispatch(showNotification({ massageType: "error", massage: "Failed to update product." }));
    }
};

export const deleteProductAction = (id) => async (dispatch) => {
    dispatch({ type: DELETE_PRODUCT_REQUEST });

    try {
        await deleteProduct(id);
        dispatch({ type: DELETE_PRODUCT_SUCCESS, payload: id });
        dispatch(showNotification({ massageType: "success", massage: "Product deleted successfully." }));
    } catch (error) {
        dispatch({ type: DELETE_PRODUCT_FAILURE, payload: error.message });
        dispatch(showNotification({ massageType: "error", massage: "Failed to delete product." }));
    }
};
