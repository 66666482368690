import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createCategoryAction,
  getAllCategoryAction,
  deleteCategoryAction,
} from '../../../../Redux/category/actions';
import Swal from 'sweetalert2';
import ReactPaginate from 'react-paginate';
import './Category.css'
const Categories = () => {
  const [categoryName, setCategoryName] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [showModal, setShowModal] = useState(false);
  
  const dispatch = useDispatch();
  const { categories, loading } = useSelector((state) => state.categories);
  
  const itemsPerPage = 5;

  useEffect(() => {
    dispatch(getAllCategoryAction());
  }, [dispatch]);

  const handleAddCategory = () => {
    if (categoryName.trim() === '') {
      Swal.fire('Error', 'Category name cannot be empty', 'error');
      return;
    }
    dispatch(createCategoryAction({ name: categoryName }));
    setShowModal(false);
    setCategoryName('');
  };

  const handleDeleteCategory = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this category!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteCategoryAction(id));
      }
    });
  };

  const filteredCategories = categories.filter((category) =>
    category?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
  );

  const pageCount = Math.ceil(filteredCategories.length / itemsPerPage);
  const offset = currentPage * itemsPerPage;
  const currentPageData = filteredCategories.slice(
    offset,
    offset + itemsPerPage
  );

  return (
    <section>
      <div className="container mx-auto px-4 sm:px-8 max-w-full sm:max-w-5xl">
        <div className="flex flex-row mb-1 sm:mb-0 justify-between w-full">
          <button
            className="px-4 py-2 text-base font-medium tracking-wide text-white bg-teal-500 rounded-lg shadow-md hover:bg-teal-700"
            onClick={() => setShowModal(true)}
          >
            Add Category
          </button>

          <div className="text-end">
            <form className="flex w-full max-w-sm justify-center">
              <input
                type="text"
                className="rounded-lg border border-gray-300 py-2 px-4 bg-white text-gray-700"
                placeholder="Search Category"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </form>
          </div>
        </div>

        <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
          <div className="inline-block shadow-3xl rounded-lg overflow-hidden" >
            <table className="min-w-[500px] leading-normal font-sans tracking-wide">
              <thead>
                <tr>
                  <th className="px-5 py-3 bg-teal-100 text-left text-sm uppercase font-semibold">
                    Category
                  </th>
                  <th className="px-5 py-3 bg-teal-100 text-center text-sm uppercase font-semibold">
                    Action
                  </th>
                </tr>
              </thead>

              <tbody>
                {currentPageData.map((category) => (
                  <tr key={category.id}>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      {category.name}
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-center">
                      <button
                        className="text-red-600 hover:text-red-900"
                        onClick={() => handleDeleteCategory(category._id)}
                      >
                       Delete
                        
                      </button>
                      
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <ReactPaginate
              previousLabel={'Previous'}
              nextLabel={'Next'}
              breakLabel={'...'}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={({ selected }) => setCurrentPage(selected)}
              containerClassName={'pagination'}
              activeClassName={'active'}
            />
          </div>
        </div>

        {/* Modal for adding category */}
        {showModal && (
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-gray-800 bg-opacity-50">
            <div className="bg-white rounded-lg p-6">
              <h2 className="text-xl font-semibold">Add New Category</h2>
              <input
                type="text"
                className="w-full mt-4 p-2 border border-gray-300 rounded-lg"
                placeholder="Category Name"
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
              />
              <div className="flex justify-end mt-4">
                <button
                  className="px-4 py-2 mr-2 text-white bg-gray-500 rounded-lg"
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </button>
                <button
                  className="px-4 py-2 text-white bg-teal-500 rounded-lg"
                  onClick={handleAddCategory}
                >
                  Add Category
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Categories;
