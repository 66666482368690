import {sendMessage, getAllMessages } from "../../Api";
import { showNotification } from "../notification/actions";

export const CREATE_MESSAGE_REQUEST = "CREATE_MESSAGE_REQUEST";
export const CREATE_MESSAGE_SUCCESS = "CREATE_MESSAGE_SUCCESS";
export const CREATE_MESSAGE_FAILURE = "CREATE_MESSAGE_FAILURE";

export const GET_MESSAGE_REQUEST = "GET_MESSAGE_REQUEST";
export const GET_MESSAGE_SUCCESS = "GET_MESSAGE_SUCCESS";
export const GET_MESSAGE_FAILURE = "GET_MESSAGE_FAILURE";



export const createMessageAction = (formData) => async (dispatch) => {
    try {
        const { data } = await sendMessage(formData);
        dispatch({ type: CREATE_MESSAGE_SUCCESS, payload: data });
        dispatch(showNotification({ massageType: "success", massage: "Message Sent successfully." }));
    } catch (error) {
        dispatch(showNotification({ massageType: "error", massage: "Failed to send message." }));
    }
};

export const getAllMessagesAction = () => async (dispatch) => {
    try {
        const { data } = await getAllMessages();
        console.log(data);
        dispatch({ type: GET_MESSAGE_SUCCESS, payload: data });
    } catch (error) {
        dispatch(showNotification({ massageType: "error", massage: "Failed to get messages." }));
    }
};
